.chat {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.header {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    background-color: var(--color-panel-header-background);
    padding: 10px 16px 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header__back {
    padding: 8px 12px;
    color: var(--color-secondary-stronger);
}

.header__picture {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    flex-grow: 0;
    flex-shrink: 0;
}

.header__icon {
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding-top: 7px;
    color: rgba(255, 255, 255, 0.8);
}

.header__details {
    padding-left: 15px;
    font-size: var(--color-base-font-size);
}

.header__title {
    font-weight: bold;
    color: var(--color-primary-strong);
    font-size: 1em;
    line-height: 1.3125em;
}

.header__status {
    font-size: 13px;
    line-height: 20px;
    color: var(--color-secondary);
}

/************************************   */

.messagesWrapper {
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: auto;
    position: relative;
    background: url("./bg.jpg") top left;
    background-size: 100%;
}

.messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 3% 10px 2%;
    min-height: 100%;
    position: relative;
}

.messages__date {
    align-self: center;
    border-radius: 7.5px;
    background-color: white;
    padding: 7px 15px;
    line-height: 1;
    font-size: 0.8em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
    opacity: 0.8;
}

.messages__date:not(:first-child) {
    margin-top: 12px;
}

.new_message_mark {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.new_message_mark__text {
    color: var(--color-chat-new-messages-mark);
    text-align: center;
    font-size: 0.85em;
    font-weight: bold;
}

.new_message_mark__line {
    width: 30%;
    border-bottom: 1px solid var(--color-chat-new-messages-mark);
    line-height: 0.1em;
}
