.wordle {
    background-color: #000;
    height: 100%;
}

.title {
    text-align: center;
    font-family: var(--font-family-title);
    text-transform: uppercase;
    color: #fff;
    padding: 20px 0;
}

.words {
    width: 100%;
}

.word {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.word__letter {
    border: 2px solid #333;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
}

.word__letter:not(:first-child) {
    margin-left: 5px;
}

.word__letter.done {
    background-color: #333;
}

.word__letter.present {
    border-color: transparent;
    background-color: #b59f3b;
}

.word__letter.correct {
    border-color: transparent;
    background-color: #538d4e !important;
}

.keyboard {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.keyboard__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5px;
    padding: 0 5px;
}

.keyboard__key {
    width: 8%;
    font-size: 12px;
    line-height: 25px;
    padding: 5px;
    font-weight: bold;
    background-color: #818383;
    color: #fff;
    border-radius: 5px;
}

.keyboard__key:not(:first-child) {
    margin-left: 5px;
}

.keyboard__key.wide {
    width: 15%;
}

.keyboard__key.disabled {
    background-color: #3a3a3c;
}

.keyboard__key.present {
    background-color: #b59f3b;
}

.keyboard__key.correct {
    background-color: #538d4e;
}

.finished {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.finished__title {
    font-size: 30px;
    font-family: var(--font-family-title);
    text-transform: uppercase;
}

.finished__title.success {
    color: #538d4e;
}

.finished__title.failed {
    color: #ab3939;
}

.finished__restart {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #818383;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 14px;
}

@media screen and (height <= 599px) {
    .title {
        padding: 15px 0;
    }

    .word__letter {
        width: 40px;
        height: 40px;
        font-size: 22px;
    }
}

@media screen and (height <= 520px) {
    .word__letter {
        width: 35px;
        height: 35px;
        font-size: 20px;
    }
}
