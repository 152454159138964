.cameraVideo {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.imageWrapper {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.image {
    width: 100%;
    height: auto;
}
