.page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #000;
    color: #fff;
}

.header {
    padding: 10px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}

.header__back {
    color: #fff;
    padding-right: 10px;
}

.header__title {
    margin-left: 10px;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text {
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
    padding: 0 1em;
}

.picture {
    width: 100%;
    height: auto;
}
