.app {
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #eff2fb;
}

.header {
    width: 100%;
    position: relative;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    flex-shrink: 0;
}

.header__title {
    font-weight: bold;
    color: #f04155;
    font-size: 20px;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 40px;
    flex-shrink: 0;
}

.footer__button {
    padding: 5px;
    color: #7d8592;
}

.footer__button.active {
    animation: bounce-top 0.9s ease-in both;
}

.footer__button.type_matches.active {
    color: #f04155;
}

.footer__button.type_messages.active {
    color: #f04155;
}

.messages {
    overflow: auto;
    flex-grow: 1;
}

.messages__contact {
    display: block;
    position: relative;
    padding-right: 1em;
    padding-left: 70px;
}

.messages__contact__iconWrapper {
    position: absolute;
    top: 0;
    left: 15px;
    bottom: 0;
    display: flex;
    align-items: center;
}

.messages__contact__details {
    padding: 1em 0;
}

.messages__contact__name {
    width: 100%;
    font-size: 16px;
    position: relative;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    color: #333;
}

.messages__contact__lastMessage {
    color: #333;
    font-size: 12px;
    margin-top: 5px;
}

.messages__contact__lastMessage__time {
    color: #999;
    margin-left: 10px;
}

.noMessages {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.noMessages__title {
    font-size: 26px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: bold;
}

.noMessages__subtitle {
    font-size: 20px;
    margin-top: 1em;
}

/**************************************   */

.thread {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.thread__title {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
}

.thread__messagesWrapper {
    overflow: auto;
    flex: 1;
}

.thread__messages {
    padding: 0 10px 10px;
}

/**************************************   */

.message {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    padding-top: 3px;
}

.message.in {
    flex-direction: row;
    margin-left: 35px;
}

.message.in.withProfile {
    margin-left: 0;
}

.message.out {
    flex-direction: row-reverse;
}

.message.in + .message.out,
.message.out + .message.in {
    margin-top: 3px;
}

.message__contactPhoto {
    margin-right: 5px;
    align-self: flex-end;
}

.message__content {
    font-size: 14px;
    display: inline-block;
}

.message__content__inner {
    border-radius: 20px;
    overflow: hidden;
}

.in .message__content__inner {
    background-color: #ececec;
}

.out .message__content__inner {
    background-color: #f38c98;
}

.message.in .message__content__inner {
    border-bottom-left-radius: 5px;
}

.message.out .message__content__inner {
    border-bottom-right-radius: 5px;
}

.message__content__inner.text {
    padding: 8px 12px;
    max-width: 250px;
}

.message__content__image {
    display: block;
    max-width: 250px;
    max-height: 250px;
}

.message__contents__text {
    display: inline;
}

.message__contents__text > p {
    margin-bottom: 0;
}

.message__contents__text > p:first-child {
    margin-top: 0;
}

.message__contents__text a {
    color: #039be5;
}

.message__time {
    margin-top: 1px;
    margin-bottom: 3px;
    font-size: 12px;
    color: #666;
}

.videoThumbnail {
    position: relative;
    cursor: pointer;
}

.videoThumbnail__image {
    max-width: 200px;
    max-height: 200px;
    vertical-align: top;
}

.videoThumbnail__icon {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    font-size: 40px;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 30px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
}

.media_controller {
    z-index: 1;
}

.media_controller__video {
    padding-bottom: var(--phone-footer-height);
}

@keyframes bounce-top {
    0% {
        transform: translateY(-24px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    24% {
        opacity: 1;
    }

    40% {
        transform: translateY(-12px);
        animation-timing-function: ease-in;
    }

    65% {
        transform: translateY(-6px);
        animation-timing-function: ease-in;
    }

    25%,
    55%,
    75%,
    87% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }

    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
}
