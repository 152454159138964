.app {
    --color-background: #161719;
    --color-title: #fff;
    --color-text: #fff;
    --color-primary: #f93c52;
    --color-button-background: var(--color-primary);
    --color-button-text: var(--color-text);

    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    position: relative;
    background: var(--color-background);
    color: var(--color-text);
}

.mainView {
    width: 200%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    transition: transform 0.1s ease-out;
}

.mainView.open {
    transform: translateX(-50%);
}

.mainView__tile {
    width: 50%;
    height: 100%;
}

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page__title {
    font-size: 18px;
    margin: 20px 0;
    text-align: center;
    width: 100%;
    position: relative;
    font-weight: 500;
}

.page__title__backButton {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 10px;
    color: var(--color-title);
}

.carImage {
    width: 80%;
    height: auto;
}

.mainMenu {
    width: 100%;
    padding: 0 5px;
    margin-top: 20px;
}

.mainMenu__item {
    height: 50px;
    display: flex;
    align-items: center;
    color: var(--color-text);
    border-radius: 5px;
}

.mainMenu__item:active {
    background-color: rgba(255, 255, 255, 0.1);
}

.mainMenu__item__iconWrapper {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainMenu__item__text {
    width: 100%;
    line-height: 1;
}

.mainMenu__item__icon {
    opacity: 0.7;
}

.controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.control {
    width: 130px;
    height: 130px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.1s linear;
}

.control:active {
    background-color: rgba(255, 255, 255, 0.1);
}

.control.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.control__icon {
    opacity: 0.8;
    height: 24px;
    width: auto;
}

.control.active .control__icon {
    color: var(--color-primary);
    opacity: 1;
}

.control:active:not(.active) .control__icon {
    color: var(--color-primary);
    opacity: 1;
}

.control__title {
    font-size: 14px;
    margin-top: 1em;
}

.cameraSelector {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.cameraSelector__item {
    font-size: 14px;
    line-height: 1;
    padding: 0.5em 1em;
    background: var(--color-button-background);
    color: var(--color-button-text);
    border-radius: 2em;
    opacity: 0.5;
    cursor: pointer;
    margin-left: 1em;
    font-weight: 500;
    transition: opacity 0.1s linear;
}

.cameraSelector__item:first-child {
    margin-left: 0;
}

.cameraSelector__item.selected {
    opacity: 1;
}

.camera {
    position: relative;
    width: 100%;
    height: 0;
}

.camera__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;
}

.camera__connecting {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    text-align: center;
    margin-top: -0.5em;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    z-index: 1;
}
