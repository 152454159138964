.app {
    --color-error: #ff42bf;
    --color-text-primary: #fff;
    --color-background: #fff;
    --color-grid-background: #fff;
    --color-tab-background: #0ff;
    --color-tab-text: #333;
    --color-tab-text-active: #333;
    --color-tab-background-active: #00e0ff;
    --color-header-background-color: #18181b;
    --color-currency: #0f3900;
    --color-item-background: #fff;
    --color-item-title-color: #00e0ff;
    --color-item-buy-button-text: #0f3900;
    --color-item-buy-button-background: #81f04c;
    --color-item-buy-tag-color: #81f04c;
    --color-item-price-tag-background: #262929;
    --color-textarea-border: #262929;

    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
    padding-bottom: var(--phone-footer-height);
}

.page__items {
    padding: 5px;
    overflow: auto;
    height: 100%;
}

.page {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    background-color: var(--color-grid-background);
    border-top: 2.5px solid var(--color-tab-divider-primary);
    box-shadow: 0 -3px 0 var(--color-tab-divider-secondary);
    margin-top: 2.5px;
    z-index: 2;
}

.item {
    width: 100%;
    text-align: center;
    padding: 15px;
    display: block;
    color: var(--color-text-primary);
    background-color: var(--color-item-background);
}

.item__imageWrapper {
    width: 100%;
}

.item__image {
    display: block;
    width: 100%;
    height: auto;
}

.item__info {
    width: 100%;
}

.item__prop__title,
.item__description__title {
    font-weight: bold;
    margin-top: 1em;
}

.item__description__title {
    margin-bottom: -1em;
}

.item__title {
    color: var(--color-item-title-color);
    font-size: 28px;
    font-weight: bold;
    margin: 0.5em 0;
}

.item__description {
    color: var(--color-text-primary);
    font-size: 16px;
}

.actionButton {
    margin-top: 15px;
}

.form {
    width: 100%;
    max-width: 500px;
    margin-top: 15px;
    padding: 0 30px;
}

.form__error {
    margin-top: 10px;
    color: var(--color-error);
}

.buyForm__textbox {
    display: block;
    width: 100%;
    height: 3em;
    border-color: var(--color-textarea-border);
    border-radius: 5px;
    padding: 0.5em;
}

.sellForm__textbox {
    border-color: var(--color-textarea-border);
    border-width: 2px;
    display: block;
    width: 100%;
    height: 3em;
    padding: 0.5em;
    border-radius: 5px;
}

.purchasedItem {
    margin-top: 20px;
}

.purchasedItem__title {
    font-weight: bold;
    font-size: 20px;
}

.purchasedItem__subtitle {
    margin-top: 5px;
}

.purchasedItem__text {
    max-width: 500px;
    padding: 1em 1.5em;
    background-color: rgba(255, 255, 255, 0.1);
    margin-top: 10px;
}

.purchasedItem__text p:first-child {
    margin-top: 0;
}

.purchasedItem__text p:last-child {
    margin-bottom: 0;
}

.unavailableItem {
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    color: var(--color-error);
}

.button {
    font-size: 24px;
    background-color: var(--color-item-buy-button-background);
    color: var(--color-item-buy-button-text);
    font-weight: bold;
    margin-top: 1em;
    padding: 0.25em 1.5em;
    border-radius: 6px;
    border: 0;
    width: fit-content;
    align-items: center;
    filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.3));
}

.button.disabled {
    opacity: 0.25;
    pointer-events: none;
}

.item__backLink {
    color: var(--color-text-primary);
    display: flex;
    width: 100%;
    padding-bottom: 0.5em;
}

.priceLabel {
    margin: 1em;
}

.priceLabel > svg > path {
    fill: var(--color-item-price-tag-background);
}

.priceLabel > svg > text {
    fill: var(--color-item-price-tag-text);
}
