.guidelines__Container {
    overflow: auto;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    text-align: left;
    z-index: var(--zindex-modal);
    margin-top: 39.333px;
}

.guidelines__scrollContainer {
    max-height: fit-content;
    overflow-y: auto;
    font-weight: normal;
    margin-bottom: 0;
}
