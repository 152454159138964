.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.05);
}

:global(.notch90) .container {
    padding-top: 40px;
}

:global(.notch67) .container {
    padding-top: 50px;
}

.container.theme_darkBlue {
    background-color: transparent;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.send_icon_theme_darkBlue {
    color: #fff;
    opacity: 1;
}

.title {
    font-size: 20px;
    line-height: 1.1;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
}

.container.theme_darkBlue .title {
    font-family: "Roboto";
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;
    padding-bottom: 0.5rem;
}

.requiredProgressText {
    line-height: 1.1;
    margin-bottom: 10px;
    font-size: 16px;
    font-style: italic;
    color: lightblue;
}

.question,
.error {
    font-size: 14px;
}

.question {
    margin: 0 0 13px;
    color: #fff;
}

.error {
    margin: 0 0 13px;
    color: #f4364c;
}

.textbox {
    width: 100%;
    border-radius: 7px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.85);
    border: 0;
}

.textbox[disabled] {
    opacity: 0.5;
}

.container.theme_darkBlue .textbox {
    line-height: 1;
    font-size: 22px;
    color: #fff;
    height: 3rem;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0) 50%
        ),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    background-color: rgba(3, 29, 67, 0.8);
    border: 1px solid #fff;
}

.submitButton {
    position: relative;
    margin-top: 13px;
}

.container.theme_darkBlue .submitButton {
    height: 3rem;
    margin: 0;
    padding: 0.5rem;
    margin-left: 0.5rem;
    opacity: 1;
}
