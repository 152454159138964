.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
    width: 100%;

    --background-color: #fff;
    --front-color: #000;

    background-color: var(--background-color);
    color: var(--front-color);
}

.root:global(.darkMode) {
    --background-color: #000;
    --front-color: #fff;

    background-color: var(--background-color);
    color: var(--front-color);
}

.title {
    position: absolute;
    font-size: 28px;
    top: 15%;
    text-align: center;
    font-weight: bold;
    font-family: monospace;
}

.description {
    position: absolute;
    top: 32%;
    font-size: 16px;
    font-family: monospace;
    text-align: center;
}

/* scanner classes only implement the outer cage of the scanner  */
.scanner {
    position: absolute;
    top: 55%;
    border-radius: 12px;
    border: 6px solid;
    text-align: center;
    padding: 20px;
    cursor: pointer;
}

.scanner::before,
.scanner::after {
    content: "";
    z-index: 1;
    position: absolute;
    background-color: var(--background-color);
}

.scanner::before {
    width: calc(100% + 20px);
    height: 85%;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
}

.scanner::after {
    width: 80%;
    height: calc(100% + 20px);
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.scanner * {
    z-index: 2;
}

/* Manages the figner print image */
.fingerprint {
    width: 100px;
    height: 100px;
    position: relative;
    background-size: 100%;
}

.fingerprint::before,
.fingerprint::after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    position: absolute;
    background-size: 100%;
}

.fingerprint.scanning::before {
    background-image: url("./fingerprint-gray.svg");
    animation: animate-finger-print 4s ease-in-out infinite;
}

/* drows the scanner line */
.fingerprint.scanning::after {
    height: 4px;
    border-radius: 4px;
    background-color: var(--front-color);
    filter: drop-shadow(0 0 10px var(--front-color));
    animation: animate-scan-line 4s ease-in-out infinite;
}

.cancel {
    margin-bottom: 60px;
    position: absolute;
    top: 82%;
    font-family: monospace;
    font-size: 20px;
}

.scanningMessage {
    display: none;
}

.scanningMessage.scanning {
    position: absolute;
    display: inherit;
    top: 83%;
    padding-top: 30px;
    width: 80%;
    font-size: 20px;
    font-family: monospace;
    padding-left: 17%;
    filter: drop-shadow(0 0 100px black);
}

.scanningMessage.scanning::after {
    overflow: hidden;
    content: "......";
    display: inline-flex;
    filter: drop-shadow(0 0 100px black);
    animation: animate-dots 2s steps(6) infinite;
}

/* animates the running dots in the scanning massage */
@keyframes animate-dots {
    0%,
    100% {
        width: 0;
    }

    50% {
        width: 80px;
    }
}

/* animates the scanner line going up and down */
@keyframes animate-scan-line {
    0%,
    100% {
        top: 0;
    }

    50% {
        top: 100%;
    }
}

/* animates the fingerprint image (two images overlapping,
   and the upper one changes its height from zero to full, 
   assumes that it is synchronized with the anipations of the
   scanner line  */
@keyframes animate-finger-print {
    0%,
    100% {
        height: 0;
    }

    50% {
        height: 100%;
    }
}
