.app {
    --color-divider: #3b7cfd;

    width: 100%;
    height: 100%;
    font-size: var(--base-font-size);
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
}

.inboxScroller {
    overflow: auto;
    height: 100%;
}

.inbox {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.inbox__header {
    padding: 10px;
    flex-shrink: 0;
    flex-grow: 0;
}

.inbox__indexNavIconWrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: calc(var(--phone-footer-height) + 20px);
    right: 10px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.33);
    background-color: rgba(255, 255, 255, 0.7);
}

.inbox__indexNavIcon {
    color: #000;
    width: 30px;
    height: 30px;
}

.inbox__headerImage {
    width: 100%;
    object-fit: contain;
}

.inbox__searchBar {
    display: block;
    width: 100%;
    position: relative;
    margin-top: 5px;
}

.inbox__searchBar__input {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-color: #ddd;
    border: none;
    border-radius: 7px;
    margin: 0;
    padding: 0 15px;
}

.inbox__searchBar__input::-webkit-search-cancel-button {
    display: none;
}

.inbox__searchBar__clear {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    line-height: 14px;
    padding: 7px 10px;
    cursor: pointer;
    display: none;
}

.inbox__searchBar__clear.visible {
    display: block;
}

.inbox__title {
    font-size: 24px;
}

.inbox__subtitle {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
    text-align: center;
}

.inbox__emails {
    border-top: 1px solid var(--color-divider);
}

.inbox__emails__noResults {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    text-align: center;
}

.inbox__email {
    border-bottom: 1px solid var(--color-divider);
    position: relative;
    padding: 15px 20px 10px;
    border-radius: 5px;
}

.inbox__email.unread::after {
    content: "•";
    color: red;
    font-size: 30px;
    position: absolute;
    left: 5px;
    top: 15%;
    transform: translateY(-50%);
}

.inbox__email__date {
    color: #666;
    position: absolute;
    font-size: 0.75em;
    top: 15px;
    right: 10px;
}

.inbox__email__details {
    overflow: hidden;
}

.inbox__email__details__from {
    width: 100%;
    font-size: 1em;
    font-weight: bold;
    position: relative;
    color: #333;
}

.inbox__email__details__count {
    font-size: 12px;
    margin-left: 5px;
}

.inbox__email__details__subject {
    font-size: 0.88em;
    font-weight: bold;
    margin-top: 5px;
    color: #333;
}

.inbox__email__details__body {
    font-size: 0.84em;
    margin-top: 6px;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inbox__email__contactPicture,
.email__contactPicture {
    margin-right: 15px;
}

/**************************************   */

.thread {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    display: flex;
}

.thread__topBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
}

.thread__backButton {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 22px;
    color: #fff;
    display: inline-block;
    align-self: center;
    padding: 10px;
    z-index: 2;
}

.thread__trashIcon {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
}

.thread__content {
    width: 100%;
    padding-bottom: 15px;
    position: relative;
    overflow: auto;
}

.thread__subjectWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--color-divider);
    min-height: 47px;
}

.thread__subject {
    font-weight: bold;
    font-size: 1.125em;
    line-height: 1.3;
    width: 85%;
}

.email {
    padding: 0 15px;
    position: relative;
    padding-top: 20px;
    border-top: 1px solid var(--color-divider);
}

.email:first-child {
    border-top: none;
}

.email__date {
    position: absolute;
    top: 20px;
    right: 15px;
    color: #666;
    font-size: 0.75em;
}

.email__fromName {
    font-size: 1em;
    max-width: calc(100% - 7.2em);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.email__to {
    font-size: 0.875em;
    margin-top: 2px;
    color: #666;
}

.email__detailsButton {
    margin-left: 5px;
    font-size: 0.88em;
    line-height: 0.875em;
    transition: transform 0.1s linear;
    display: inline-block;
}

.email__detailsButton.open {
    transform: rotate(180deg);
}

.email__details {
    display: none;
    font-size: 0.875em;
    border: 1px solid #ddd;
    border-radius: 0.3125em;
    margin-top: 0.3125em;
    padding: 0.3125em;
    grid-template-columns: auto auto;
    grid-gap: 0.625em;
}

.email__details.visible {
    display: grid;
}

.email__details__item__title {
    color: #666;
}

.email__details__item__value {
    color: #333;
}

.email__body {
    font-size: 1em;
    line-height: 1.5;
}

.email__body img {
    max-width: 100%;
    height: auto;
}

.email__attachment {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.email__attachment__type {
    width: 40px;
    height: 40px;
    background-color: var(--color-divider);
    line-height: 40px;
    text-align: center;
    color: white;
    border-radius: 5px;
    margin-right: 10px;
    flex-shrink: 0;
}

.email__attachment__details {
    flex-grow: 1;
}

.email__attachment__name {
    color: #333;
    font-size: 1em;
}

.email__attachment__size {
    color: #999;
    font-size: 0.875em;
    margin-top: 2px;
}

.email__attachment__download {
    font-size: 25px;
    color: var(--color-divider);
    flex-shrink: 0;
    display: block;
    height: 25px;
}

/**************************************   */

.attachment__downloading {
    width: 100%;
    font-size: 16px;
    text-align: center;
    padding-top: 100px;
}

.attachment__password {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.attachment__password__text {
    display: inline-block;
    font-size: 16px;
}

.attachment__password .attachment__password__textbox__form {
    margin-top: 10px;
}

.attachment__password__textbox {
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #ddd;
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 30px;
}

.attachment__password__textbox.error {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

.attachment__password__submit {
    font-size: 16px;
    background-color: var(--color-divider);
    color: #000;
    padding: 5px 20px;
    border-radius: 20px;
    border: none;
    display: inline-block;
    margin-top: 10px;
}

.attachment__mediaWrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
}

.attachment__image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.attachment__audio {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.attachment__audio__title {
    margin-bottom: 12px;
    font-size: 1.1em;
}

.attachment__audio__player {
    background-color: #c7c6c6;
    border-radius: 20px;
    width: 100%;
    max-width: 300px;
    display: grid;
    place-items: center;
}

.attachment__audio__player .rhap_container {
    width: 100% !important;
    padding: 10px;
}

.attachment__audio__player .rhap_progress-section {
    margin-left: -8%;
    margin-right: 10px;
}

.attachment__audio__player .rhap_progress-container {
    margin-right: calc(10px + 2%);
}

.attachment__audio__player .rhap_controls-section {
    margin: 0 !important;
}

.attachment__audio__player .rhap_progress-indicator {
    background-color: #434343;
}

.attachment__audio__player .rhap_main-controls-button {
    color: #434343;
}

.attachment__audio__transcript,
.attachment__audio__transcriptContainer {
    background-color: #c7c6c6;
    padding: 0.8em 1em;
    max-height: 150px;
    margin-top: 10px;
    border-radius: 15px;
    overflow-y: scroll;
    width: 100%;
    max-width: 300px;
}

.attachment__audio__transcript-container {
    background-color: transparent;
    height: 150px;
    padding: 0;
}

.attachment__audio__transcript__text {
    font-size: 14px;
    line-height: 1.2;
}

.attachment__audio__transcript__text > p:first-child {
    margin-top: 0;
}

.attachment__audio__transcript__text > p:last-child {
    margin-bottom: 0;
}

.attachment__audio__transcriptButton {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 7px;
    text-align: center;
}

@keyframes shake {
    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}
