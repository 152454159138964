.browser {
    width: 100%;
    height: 100%;
    font-size: var(--base-font-size);
    padding-bottom: var(--phone-footer-height);
    display: flex;
    flex-direction: column;

    --color-cached-page: #9e1a7e;
}

.search {
    width: 100%;
    padding: 10px;
    flex-shrink: 0;
    flex-grow: 0;
}

.search__input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #ddd;
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 30px;
}

.history__item {
    width: 100%;
    padding: 0.5em 1em;
    color: #333;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    align-items: center;
}

.history__item__text,
.history__item__text > p {
    margin: 0;
    width: 100%;
}

.history__item__icon {
    color: #666;
    margin-right: 12px;
    font-size: 0.875em;
    flex-shrink: 0;
    flex-grow: 0;
}

.history__item__cached {
    font-size: 11px;
    line-height: 1;
    padding: 2px 4px;
    color: #fff;
    background-color: var(--color-cached-page);
    border-radius: 10px;
    font-weight: bold;
    margin-left: 0.5em;
    white-space: nowrap;
}

.history__more {
    text-align: center;
    font-style: italic;
    color: #666;
    font-size: 14px;
    margin-top: 5px;
}

.offline {
    padding: 2em;
    color: #333;
}

.offline__title {
    font-weight: normal;
}

.offline__text {
    margin-top: 1em;
    font-size: 1em;
    line-height: 1.3;
}

.cached {
    flex-grow: 0;
    flex-shrink: 1;
    overflow: auto;
}

.cached__note {
    text-align: center;
}

.cached__note__inner {
    display: inline-block;
    padding: 3px 7px;
    margin-bottom: 5px;
    color: #fff;
    background-color: var(--color-cached-page);
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
}

.cached__image {
    width: 100%;
}

.cached__content {
    padding: 0 5%;
    color: #333;
}

.cached__content img {
    max-width: 100%;
    height: auto;
}
