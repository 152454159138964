.converter {
    padding: 50px 20px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.converter__input {
    display: inline-block;
    width: 80%;
    line-height: 2;
    background-color: #ddd;
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 30px;
    font-size: 24px;
    text-align: center;

    /* Fix for Firefox */
    appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
.converter__input::-webkit-outer-spin-button,
.converter__input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

.converter__headline {
    display: block;
    font-size: 20px;
    margin-bottom: 1em;
}

.converter__result {
    font-size: 20px;
    margin-top: 1em;
    line-height: 1.3;
}

.converter__result__value {
    font-weight: bold;
    font-size: 1.5em;
    margin: 0.3em 0;
}
