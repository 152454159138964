.editor {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px 10px 0;
}

.editor__content {
    height: 100%;
    overflow: hidden;
}

.editor__tabs {
    flex-shrink: 0;
    flex-grow: 0;
}
