.hacker {
    width: 90%;
    max-height: 90%;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    color: #00ff00;
    border: 2px solid #00ff00;
    border-top-width: 15px;
    font-family: monospace;
    margin: 0;
    white-space: pre-wrap;
    word-break: break-all;
    padding: 1em;
    position: relative;
}

.hacker__header {
    width: 100%;
    height: 15px;
}

.hacker__icon {
    position: absolute;
    width: 54px;
    top: -34px;
    left: calc(50% - 27px);
    border: solid 2px #00ff00;
    border-radius: 7px;
    z-index: 20;
}
