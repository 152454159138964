.screen {
    --color-background: #e2e2e2;
    --color-textbox-background: rgba(255, 255, 255, 0.9);
    --color-textbox-text: #333;
    --color-button-background: #4db5ff;
    --color-button-text: #333;

    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 20px 20px 50px;
    background: var(--color-background);
}

.screen__form {
    position: relative;
}

.screen__form__footer {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.textboxForm {
    margin-top: 10px !important;
}

.textbox {
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: var(--color-textbox-background);
    color: var(--color-textbox-text);
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 50px 0 30px;
    flex-shrink: 0;
}

.textbox.username {
    margin-top: 10px;
}

.textbox.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.submitButton {
    font-size: 16px;
    background: var(--color-button-background);
    color: var(--color-button-text);
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    display: inline-block;
    margin-top: 10px;
    width: 100%;
    flex-shrink: 0;
    position: relative;
}

.submitButton.disabled {
    pointer-events: none;
    opacity: 0.7;
}

.submitButton.error {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

.submitButton__spinnerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading .submitButton__content {
    visibility: hidden;
}

.errorLabel {
    color: red;
    margin-top: 15px;
    flex-shrink: 0;
    line-height: 1;
}

.forgotPasswordLink {
    margin-top: 15px;
    font-size: 16px;
    line-height: 1;
}

.forgotPasswordLink.clickable {
    text-decoration: underline;
}

.hintButton {
    border: 1px solid #f3e779;
    border-radius: 20px;
    font-size: 13px;
    padding: 3px 7px;
    background-color: #fffacf;
    color: #333;
    margin-top: 10px;
}

.hintButton__icon {
    vertical-align: top;
    margin-right: 0.2em;
}

@keyframes shake {
    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}
