.collapsibleBlock {
    display: block;
    color: rgba(164, 226, 255, 1);
    font-family: var(--font-family-default);
    font-size: 20px;
    letter-spacing: 0;
    text-align: left;
    margin: auto;
    padding: 2% 5%;
    margin-top: 24px;
    border-radius: 10px;
    background: linear-gradient(
            360deg,
            rgba(167, 234, 255, 0) 44.72%,
            rgba(167, 234, 255, 0.1) 100%
        ),
        linear-gradient(
            0deg,
            rgba(167, 234, 255, 0.1),
            rgba(167, 234, 255, 0.1)
        );
}

.collapsibleBlock__header {
    font-weight: bold;
    justify-content: space-between;
    width: 100%;
}

.collapsibleBlock__icon {
    margin: auto 0;
    transition-property: transform;
    transition-duration: 0.7s;
}

.collapsibleBlock__icon.open {
    transform: rotate(-180deg);
}

.collapsibleBlock__content {
    display: block;
    height: 0;
    color: #fff;
    font-size: 18px;
    overflow-x: hidden;
    overflow-y: scroll;
    transition-property: height, visibility;
    transition-duration: 0.4s;
}

.collapsibleBlock__content.open {
    height: 70px;
    margin: 1rem 0;
    visibility: visible;
}

.collapsibleBlock__content.longText {
    height: 120px;
}

.text > p:first-child {
    margin-top: 0;
}

.text > p:last-child {
    margin-bottom: 0;
}

.progressBarContainer {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-shadow:
        0 3px 8px rgba(0, 0, 0, 0.4),
        inset 0 0 5px rgba(255, 255, 255, 0.3);
    background: linear-gradient(180deg, #233036 0%, #1a2328 100%),
        linear-gradient(0deg, #43565c, #43565c);
}

.innerCircle {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.centeredText {
    position: absolute;
    font-family: "Bungee";
    font-family: var(--font-family-title);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: normal;
    line-height: 1em;
    text-align: center;
    top: calc(50% - 0.5em);
    margin: auto;
    width: 100%;
    letter-spacing: 2px;
}
