.title {
    height: 3em;
    line-height: 3em;
    font-size: 18px;
    color: white;
    background-color: #16325b;
    text-align: center;
}

.entry {
    padding: 20px 30px;
    border-bottom: 1px solid #eee;
    position: relative;
    color: #16325b;
}

.entry__code {
    font-size: 30px;
    display: flex;
    align-items: center;
}

.entry__name {
    margin-top: 5px;
    font-size: 16px;
}

.progressBar {
    width: 0.95em;
    height: 0.95em;
    margin-left: 0.3em;
}

.progressBar :global(.CircularProgressbar) {
    vertical-align: unset;
}
