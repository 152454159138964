.header {
    flex: 0 0;
    justify-content: center;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    z-index: 1;
}

.header_image {
    display: flex;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}

.siteName {
    font-size: 16px;
    font-weight: bold;
    position: relative;
}

.link {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    margin-left: 0.8em;
}

.balanceContainer {
    position: absolute;
    flex: 0 0;
    display: flex;
    flex-direction: column;
    right: 10px;
    top: 0;
    height: 100%;
    align-items: flex-end;
    justify-content: space-around;
}

.balanceContainer__balance {
    color: var(--color-balance-text-secondary);
    background-color: var(--color-balance-background);
    padding: 0.25em;
    text-align: right;
    border: 2px solid var(--color-balance-border);
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
}

.balanceValue {
    color: var(--color-balance-text-primary);
    font-size: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-shadow:
        -0.35px -0.35px 0 var(--color-balance-text-primary),
        0.35px -0.35px 0 var(--color-balance-text-primary),
        -0.35px 0.35px 0 var(--color-balance-text-primary),
        0.35px 0.35px 0 var(--color-balance-text-primary);
}

.currency {
    font-size: 1.1em;
    margin-right: 5px;
}

.coins::before {
    content: "⬤";
    display: inline-block;
    vertical-align: top;
    font-size: 0.7em;
    color: var(--color-currency);
    margin-right: 0.4em;
}

.tabs {
    overflow: hidden;
    display: flex;
    gap: 8px;
    margin-top: 16px;
    padding: 0 4px;
}

.tab {
    text-transform: uppercase;
    background-color: var(--color-tab-background);
    padding: 0.5em;
    color: var(--color-tab-text);
    width: 10rem;
    font-size: 16px;
    text-align: center;
    line-height: 1;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    position: relative;
    user-select: none;
}

.tab.active {
    color: var(--color-tab-text-active);
    background-color: var(--color-tab-background-active);
    cursor: pointer;
}

.tab .sub {
    font-size: 0.5rem;
}

.tab .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
