.calculator {
    --color-calculator-background: #fff;
    --color-calculator-value: #333;
    --color-calculator-primary: #88c100;
    --color-calculator-secondary: #ff8a00;
    --color-calculator-key-background: #f2f2f2;
    --color-calculator-key-text: #1e1e1e;
    --color-calculator-key-equal-text: #fff;
    --color-calculator-paste-button-background: #333;
    --color-calculator-paste-button-text: #fff;

    background-color: var(--color-calculator-background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 0;
    height: 100%;
    padding-bottom: var(--phone-footer-height);
}

.calculator__value {
    font-size: 35px;
    text-align: left;
    width: 100%;
    flex-grow: 1;
    padding: 20px 10px;
    line-height: 1;
    position: relative;
}

.calculator__value__inner {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    overflow: hidden;
    overflow-wrap: break-word;
    display: block;
    border: 0;
    padding: 0;
    background-color: var(--color-calculator-background);
    color: var(--color-calculator-value);
}

.calculator__keyboard {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    touch-action: none;
}

.calculator__key {
    font-size: 25px;
    line-height: 25px;
    padding: 10px;
    background-color: var(--color-calculator-key-background);
    color: var(--color-calculator-key-text);
    border-radius: 20px;
    margin: 10px;
    position: relative;
    overflow: hidden;
}

.calculator__key::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: currentcolor;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s;
}

.calculator__key:active::after {
    opacity: 0.2;
}

.calculator__key.c {
    color: var(--color-calculator-secondary);
}

.calculator__key.op {
    color: var(--color-calculator-primary);
}

.calculator__key.eq {
    background-color: var(--color-calculator-primary);
    color: var(--color-calculator-key-equal-text);
    font-size: 40px;
}

.pasteButton {
    padding: 6px 15px;
    background-color: var(--color-calculator-paste-button-background);
    border-radius: 15px;
    color: var(--color-calculator-paste-button-text);
    font-size: 14px;
    position: relative;
    cursor: pointer;
}

.pasteButton::after {
    content: "";
    position: absolute;
    top: -6px;
    left: calc(50% - 9px);
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid var(--color-calculator-paste-button-background);
}
