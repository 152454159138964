.locker {
    display: block;
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 1;
    transition:
        visibility 0s linear 0s,
        opacity 1s linear;
}

.locker.unlocked {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition:
        visibility 0s linear 1s,
        opacity 1s linear;
}

.locker.transparent {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(15px);
}

/*******************************************   */

.usernamePassword__logo {
    width: 30%;
    height: auto;
    border-radius: 10px;
}

/*******************************************   */

.tilePuzzle {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 80px 10px 20px;
    height: 100%;
}

.tilePuzzle__title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}
