.videoCall {
    position: absolute;
    z-index: var(--zindex-phone-call);
    top: 20px;
    left: 0;
    width: 100%;
    height: calc(100% - 20px);
    overflow: hidden;
    background-color: black;
}

.incoming {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: linear-gradient(
        30deg,
        rgba(43, 19, 60, 1) 0%,
        rgba(136, 18, 18, 1) 50%,
        rgba(113, 81, 35, 1) 100%
    );
}

.incoming__name {
    font-size: 30px;
    color: white;
    margin-top: 50px;
}

.incoming__subtitle {
    color: white;
    font-size: 16px;
    margin-top: 10px;
}

.incoming__buttons {
    position: absolute;
    bottom: 50px;
    width: 100%;
    height: 50px;
}

.incoming__button {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    line-height: 60px;
}

.incoming__button.answer {
    background-color: #00cc4c;
    margin-right: 100px;
}

.incoming__button.decline {
    background-color: #ef3248;
}

.incoming__button__icon {
    display: inline-block;
    font-size: 40px;
    color: white;
}

.answer > .incoming__button__icon {
    transform: translate(1px, 2px);
}

.decline > .incoming__button__icon {
    transform: rotate(135deg) translate(1px, 0);
}

.activeVideoCall {
    opacity: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
}

.activeVideoCall.active {
    opacity: 1;
    pointer-events: auto;
}

.endButton {
    position: absolute;
    z-index: 100;
    bottom: 50px;
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    border-radius: 60px;
    line-height: 60px;
    background-color: #ef3248;
    text-align: center;
    cursor: pointer;
}

.endButton__icon {
    display: inline-block;
    font-size: 40px;
    color: white;
    transform: rotate(135deg) translate(1px, 0);
}

.videoWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video__player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video__loading {
    color: white;
    font-size: 16px;
    text-align: center;
    position: absolute;
    top: 70px;
    width: 100%;
}

.video__subtitles_icon {
    position: absolute;
    left: calc(50% + 60px);
}
