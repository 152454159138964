.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
}

.title {
    font-size: 20px;
    text-align: center;
}

.root:global(.darkMode) {
    color: #fff;
}

.keyboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    margin-top: 20px;
    z-index: 2;
}

.keyboard.animated {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

.key {
    padding: 20px;
    margin: 16px;
    z-index: 2;
}

.key::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    border: 1px solid #333;
    transition: background-color 0.1s;
}

.key:global(.on)::before {
    background-color: #333;
}

.key:global(.darkMode)::before {
    border: none;
    background-color: rgba(213, 226, 226, 1);
}

.key:global(.darkMode):global(.on)::before {
    background-color: rgba(255, 255, 255, 1);
    outline: 10px solid rgba(141, 229, 229, 0.3);
}

.pasteButton {
    padding: 6px 15px;
    background-color: #333;
    border-radius: 15px;
    color: #fff;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: -20px;
}

.pasteButton::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: calc(50% - 9px);
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #333;
}

@keyframes shake {
    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}

.linesCanvas {
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    z-index: 1;
}
