.app {
    --color-background: #eff2fb;
    --color-title: #000;
    --color-item-details-background: rgba(255, 255, 255, 0.8);
    --color-item-details-text: #000;

    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
}

.header {
    width: 100%;
    position: relative;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    flex-shrink: 0;
}

.header__title,
.header__done {
    color: var(--color-title);
    transition:
        transform 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
}

.header__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 1;
}

.header__done {
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 16px;
}

.header__title.hidden,
.header__done.hidden {
    pointer-events: none;
    transform: translateX(-150%);
    opacity: 0;
}

.header__done__icon {
    vertical-align: top;
}

.items {
    position: relative;
    width: 100%;
    height: 100%;
}

.item {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    padding: 0 3%;
}

.item__link {
    display: block;
    overflow: hidden;
    border-radius: 15px;
}

.item.focused .item__link {
    pointer-events: none;
}

.item__image {
    width: 100%;
    height: auto;
    display: block;
}

.item__details {
    background-color: var(--color-item-details-background);
    color: var(--color-item-details-text);
    border-radius: 15px;
    padding: 10px;
    margin-top: 20px;
}
