.animation {
    width: 100%;
    align-self: flex-start;
    height: calc(var(--safe-area-inset-top) + 247px);
    background: linear-gradient(
        0deg,
        rgba(217, 217, 217, 0) 0%,
        rgba(0, 0, 0, 0.5) 40%,
        #000 100%
    );
}

.content {
    position: absolute;
    top: var(--safe-area-inset-top);
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    animation: enter-exit ease-in-out 3s 0.3s both;
}

.giftBox {
    width: 60%;
    height: auto;
}

.checkmarks {
    position: absolute;
    bottom: 20%;
}

.checkmark {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 -1px;
    animation: fade-in ease-in-out 0.3s 0.6s both;
}

.checkmark__image {
    width: 38px;
    height: auto;
    position: relative;
    z-index: 1;
}

.checking .checkmark__image:not(.checking) {
    animation: fade-out ease-in-out 0.1s 0.9s both;
}

.checking .checkmark__image.checking {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    animation: fade-in ease-in-out 0.2s 0.9s both;
}

@keyframes enter-exit {
    0% {
        transform: translateY(-200px);
    }

    10% {
        transform: translateY(0);
    }

    90% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-200px);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
