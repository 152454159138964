.app {
    width: 100%;
    height: 100%;
    padding-bottom: var(--phone-footer-height);
    font-family: monospace;
    background-color: #000;
    color: #fff;
    overflow: auto;
}

.mapWrapper {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
}

.map {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.iconWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    margin-left: -30px;
}

.icon.animate {
    transition: transform 0.8s ease-in-out;
}

.detail {
    text-align: center;
    margin-top: 15px;
}

.detail__title {
    color: #999;
    text-transform: uppercase;
}

.detail__text {
    font-size: 1.3em;
    font-weight: bold;
    margin-top: 2px;
}

.actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    user-select: none;
    touch-action: manipulation;
    min-width: 50%;
    text-align: center;
    white-space: nowrap;
    margin-top: 25px;
}

.button.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.button__shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg, 0%, 0%, 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.button__edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
        to left,
        hsl(340deg, 100%, 16%) 0%,
        hsl(340deg, 100%, 32%) 8%,
        hsl(340deg, 100%, 32%) 92%,
        hsl(340deg, 100%, 16%) 100%
    );
}

.button__front {
    display: block;
    position: relative;
    padding: 12px 27px;
    border-radius: 12px;
    font-size: 1.1rem;
    color: white;
    background: hsl(345deg, 100%, 47%);
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.button:active .button__front {
    transform: translateY(-2px);
    transition: transform 34ms;
}

.button:active .button__shadow {
    transform: translateY(1px);
    transition: transform 34ms;
}

.button:focus:not(:focus-visible) {
    outline: none;
}
