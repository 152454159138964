.app {
    --color-background: #eff2fb;
    --color-title: #000;
    --color-text: #000;
    --color-primary: #4e96b3;
    --color-button-background: var(--color-primary);
    --color-button-text: var(--color-text);

    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
    position: relative;
}

.orderPage {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.orderPage__background {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.orderPage__ridesButton {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 16px;
    color: var(--color-text);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    padding: 10px 15px;
    font-weight: bold;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.3);
}

.searchBox {
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 20%;
    left: 10%;
    width: 80%;
    border-radius: 30px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.3);
}

.searchBox__icon {
    position: absolute;
    top: 10px;
    left: 15px;
}

.searchBox__input {
    width: 100%;
    border-radius: 30px;
    border: none;
    padding: 13px 46px;
    font-size: 16px;
    line-height: 1;
    background-color: transparent;
}

.searchBox__searchButton {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 36px;
    width: 36px;
    background-color: var(--color-button-background);
    color: var(--color-button-text);
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.1s;
}

.searchBox__searchButton.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.searchBox__searchButton__icon {
    display: block;
    transform: rotate(90deg);
}

.activeRide {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: var(--color-background);
    animation: active-ride-enter 0.3s ease-in-out both;
}

.activeRide__title {
    font-weight: bold;
    padding: 0 50px 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.arriving .activeRide__title {
    min-height: 50px;
}

.activeRide__arrival {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: var(--color-primary);
    text-align: center;
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
}

.activeRide__number {
    font-size: 16px;
}

.activeRide__details {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.activeRide__details__driverPhoto {
    height: 50px;
    border: 2px solid var(--color-primary);
    border-radius: 50%;
    position: relative;
    z-index: 2;
    flex: 0;
}

.activeRide__details__carPhoto {
    height: 50px;
    margin-left: -20px;
    position: relative;
    z-index: 1;
    flex: 0;
}

.activeRide__details__info {
    text-align: right;
    width: 100%;
}

.activeRide__details__driverName {
    font-weight: bold;
    font-size: 13px;
}

.activeRide__details__licensePlate {
    font-weight: bold;
    font-size: 22px;
}

.activeRide__details__carType {
    font-size: 13px;
}

.searching {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: fade-in 0.3s ease-in-out both;
}

.searching__title {
    font-size: 24px;
}

.searching__subtitle {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 8px;
}

.searching__spinner {
    margin-top: 30px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.searching__spinner.hidden {
    opacity: 0;
}

.searching__closeButton {
    background-color: var(--color-button-background);
    color: var(--color-button-text);
    padding: 10px 40px;
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
    animation: fade-in 0.3s ease-in-out both;
    font-weight: bold;
}

.rides {
    position: absolute;
    top: 0;
    left: 0;
    width: 112%;
    height: 100%;
    z-index: 20;
    transform: translateX(-100%);
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    background-color: var(--color-background);
    padding-left: 12%;
    padding-bottom: var(--phone-footer-height);
    display: flex;
    flex-direction: column;
}

.rides.open {
    transform: translateX(-10%);
}

.rides__header {
    padding: 10px;
    display: flex;
    align-items: center;
    line-height: 1;
    flex-shrink: 0;
}

.rides__closeButton {
    color: #000;
    padding: 0 5px;
}

.rides__title {
    margin-left: 10px;
    font-weight: bold;
    font-size: 18px;
}

.rides__list {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.rideHistoryItem {
    position: relative;
}

.rideHistoryItem:not(:first-child) {
    margin-top: 30px;
}

.rideHistoryItem__ellipsis {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
}

.rideHistorySubItem {
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 10px;
}

.rideHistorySubItem.topMargin {
    margin-top: 20px;
}

.rideHistorySubItem__icon {
    flex-shrink: 0;
}

.rideHistorySubItem__info {
    width: 100%;
    margin-left: 20px;
}

.rideHistorySubItem__title {
    font-size: 16px;
}

.rideHistorySubItem__subtitle {
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    margin-top: 4px;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes active-ride-enter {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
