.MediaSlider {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #000;
}

.MediaSlider-inner {
    height: 100%;
    transition: transform 0.2s;
}

.MediaSlider-inner.dragging {
    transition: none;
}

.MediaSlider-item {
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
}
