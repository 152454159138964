.gestureLocker {
    --color-gesture-locker-background: #fff;
    --color-gesture-locker-text: #000;
    --color-gesture-locker-canvas-background: #ddd;
    --color-gesture-locker-flash: rgba(255, 0, 0, 0.834);
    --color-gesture-locker-success: rgba(0, 136, 0, 0.334);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 80px 20px 20px;
    height: 100%;
    width: 100%;
    background-color: var(--color-gesture-locker-background);
    color: var(--color-gesture-locker-text);
}

.gestureLocker:global(.darkMode) {
    --color-gesture-locker-background: "transparent";
    --color-gesture-locker-text: #fff;
    --color-gesture-locker-canvas-background: #1d2125;
}

.title {
    font-size: 20px;
    margin-bottom: 20px;
}

.title.small {
    font-size: 14px;
    color: #999;
}

.painterContainer {
    margin-bottom: 20px;
    background-color: var(--color-gesture-locker-canvas-background);
    border-radius: 10px;
}

.painterContainer.attemptAnimation {
    animation: animate-flash-color 0.3s 5;
}

.painterContainer.successAnimation {
    background-color: var(--color-gesture-locker-success);
}

.cancel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 60px;
}

/* animate: flash the square in error color for incorrect gesture . */
@keyframes animate-flash-color {
    0%,
    100%,
    40%,
    70% {
        background-color: var(--color-gesture-locker-canvas-background);
    }

    50% {
        background-color: var(--color-gesture-locker-flash);
    }
}
