.alert {
    position: absolute;
    left: 0;
    top: 0;
    z-index: var(--zindex-alert);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
}

.popup {
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    z-index: var(--zindex-alert);
}

.contents {
    width: 90%;
    left: 5%;
    max-height: 90%;
    max-width: 500px;
    max-width: min(500px, 90%);
    background-color: white;
    border-radius: var(--container-border-radius);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: visible;
    padding: 0 2em 0.5em;
    margin-bottom: 10px;
    font-family: var(--font-family-default);
}

.contents.align_left {
    align-items: flex-start;
}

.align_center {
    text-align: center;
}

.align_left {
    text-align: left;
}

.contents.fitContent {
    width: fit-content;
}

.scroller {
    margin-top: 1em;
    overflow-y: auto;
}

.bigIcon {
    width: 30%;
    height: auto;
    margin: 0 auto 10px;
}

.title {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    padding-top: 0.5em;
}

.title,
.text {
    font-size: 18px;
    line-height: 1.3;
    width: 100%;
}

.text > p:first-child {
    margin-top: 0;
}

.text > p:last-child {
    margin-bottom: 0;
}

.center .title,
.center .text {
    text-align: center;
}

.buttons {
    position: relative;
    width: 100%;
    text-align: right;
    margin-bottom: 1em;
}

/* default theme */

.theme_default .contents {
    padding: 20px 30px;
}

.theme_default .scroller {
    margin-top: 0;
}

.theme_default .title {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
}

.theme_default .text,
.theme_default .title {
    font-size: 16px;
}

.theme_default .buttons {
    margin: 5px 0 0;
}

.theme_default .button {
    font-size: 16px;
    color: var(--color-text-primary);
    padding: 10px 0 0 20px;
    display: inline-block;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
}

.theme_default .button.secondary {
    color: #999;
}

.theme_default .button.danger {
    color: var(--color-text-error);
}

/* Theme darkBlue */
.theme_darkBlue .title {
    padding: 0 0.5em;
    text-align: center;
}

.theme_darkBlue .text {
    line-height: 1.2;
    text-align: center;
}

.theme_darkBlue .contents {
    backdrop-filter: blur(10px);
    padding: 0 24px;
}

.theme_darkBlue .scroller {
    margin: 39.333px 0;
    overflow-y: auto;
}

.buttons.theme_darkBlue {
    color: var(--color-text-primary);
    flex-direction: column-reverse;
    align-items: center;
    padding: unset;
    margin: auto;
    margin-bottom: 0;
    padding-bottom: 30px;
}

.buttons.theme_darkBlue .button {
    text-decoration: none;
}

.theme_darkBlue.topMargin {
    margin-top: 30px;
}

.buttons.theme_darkBlue > .button:not(:last-child) {
    margin-top: 8px;
}
