.app {
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding: 1em;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.theme_dark {
    color: #fff;
}

.app__backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.title,
.logo {
    font-size: 2em;
    margin-top: 1em;
}

.logo {
    width: 80%;
    height: auto;
}

.description {
    margin-top: 40px;
    font-size: 1.125em;
}

.description > p:first-child {
    margin-top: 0;
}

.description > p:last-child {
    margin-bottom: 0;
}

.gesturePad {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gesturePad__prompt {
    margin-bottom: 0.5em;
}

.gesturePad__inner {
    background-color: rgba(0, 0, 0, 0.3);
}

.theme_dark .gesturePad__inner {
    background-color: rgba(255, 255, 255, 0.3);
}
