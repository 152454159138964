.contextMenu {
    position: absolute;
    z-index: var(--zindex-paste-context-menu);
    padding: 0.7em 1.6em;
    background-color: rgb(36, 36, 36);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    color: rgba(255, 255, 255, 0.9);
    transform: translate3d(-50%, calc(-100% - 7px), 0);
    font-size: 15px;
    animation: enter 0.3s;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.contextMenu.bottomPosition {
    transform: translate3d(-50%, 7px, 0);
}

.contextMenu::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: calc(50% - 5px);
    width: 9px;
    height: 9px;
    border-radius: 3px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: inherit;
    background-color: inherit;
    transform: rotate(45deg);
}

.contextMenu.bottomPosition::after {
    transform: rotate(-135deg);
    bottom: unset;
    top: -5px;
}

.contextMenu.entered {
    opacity: 1;
}

@keyframes enter {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
