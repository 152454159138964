.grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 12px;
    padding: 12px;
    padding-bottom: 20px;
    background-color: var(--color-grid-background);
}

.item {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--color-item-background);
    color: var(--color-text-primary);
    height: 210px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    padding: 4.5px;
    justify-content: space-between;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.15));
}

.indexItem__imageWrapper {
    height: 100%;
    transition: opacity 0.3s ease-in;
    opacity: 1;
    padding: 5px;
}

.indexItem__imageWrapper__placeholder {
    opacity: 0;
    height: 100px;
    margin-bottom: 4.5px;
    background-color: rgba(255, 255, 255, 0.1);
}

.indexItem__imageWrapper__loaded {
    height: 100%;
    height: 100px;
    opacity: 1;
}

.item__image {
    height: 100px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.item__info {
    text-align: center;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item__title {
    font-size: 16px;
    line-height: 1.25;
    width: 100%;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item__price {
    color: var(--color-item-buy-button-text);
    background-color: var(--color-item-buy-button-background);
    font-weight: bold;
    padding: 0.25em 1.5em;
    border-radius: 6px;
    width: fit-content;
    align-items: center;
    margin: auto;
    font-size: 14px;
    margin-bottom: 5px;
}

.coins::before {
    content: "⬤";
    vertical-align: text-top;
    color: var(--color-currency);
    margin-right: 0.4em;
}

.currency {
    font-size: 1.1em;
    margin-right: 5px;
    color: var(--color-currency);
}
