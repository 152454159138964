.app {
    --color-background: #1d1d1d;
    --color-text: #fafafa;
    --color-title: var(--color-text);
    --color-selector-item-background: #2e2e2e;
    --color-selector-item-text: var(--color-text);
    --color-feed-alert-background: red;
    --color-feed-alert-text: white;
    --color-control-background: yellow;
    --color-control-text: #000;
    --color-instructions-text: var(--color-text);

    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    background-color: var(--color-background);
    color: var(--color-text);
}

.header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__title {
    color: var(--color-title);
    font-weight: 500;
}

.zoomInstructions {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    color: var(--color-instructions-text);
}

.scroller {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}

.selector {
    margin-top: 10px;
    padding: 0 20px;
    text-align: center;
}

.selector__item {
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    padding: 0.5em 1em;
    background: var(--color-selector-item-background);
    color: var(--color-selector-item-text);
    border-radius: 2em;
    opacity: 0.5;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 1em;
    font-weight: 500;
    transition: opacity 0.1s linear;
}

.selector__item:first-child {
    margin-left: 0;
}

.selector__item.active {
    opacity: 1;
}

.controls {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.control.button {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 0.5em 1em;
    background: var(--color-control-background);
    color: var(--color-control-text);
    border-radius: 2em;
    cursor: pointer;
    font-weight: 500;
}

.control.button:active {
    opacity: 0.9;
    transform: translateY(1px) scale(0.99);
}
