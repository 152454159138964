.yearModalWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
}

.yearModal {
    position: absolute;
    width: 90%;
    bottom: 50px;
    left: 5%;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    z-index: 10;
}

.yearModal__title {
    text-align: center;
    font-size: 16px;
}

.yearModal__nav {
    position: absolute;
    top: 15px;
    font-size: 20px;
}

.yearModal__nav.back {
    left: 15px;
}

.yearModal__nav.forward {
    right: 15px;
}

.yearModal__items {
    display: inline-grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    justify-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.yearModal__item {
    width: 45px;
    height: 45px;
    font-size: 12px;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #999;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
}

.yearModal__item.selected {
    background-color: var(--color-theme-app-calendar);
    border-color: var(--color-theme-app-calendar);
    color: #fff;
}

.yearModal__item.events {
    box-shadow: 0 0 5px var(--color-theme-app-calendar-secondary);
    border-color: var(--color-theme-app-calendar-secondary);
}
