.app {
    height: 100%;
    width: 100%;
    padding-bottom: var(--phone-footer-height);
}

.app__contents {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-image: linear-gradient(to bottom, #ee745d 0%, #e25251 100%);
}

.home,
.ordered,
.tracker {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 10%;
    height: 100%;
}

.tracker {
    padding-right: 0;
    padding-left: 0;
}

.home__hero {
    display: block;
    width: 50%;
    height: auto;
    margin-top: 50px;
}

.home__keyboardWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition:
        visibility 0s linear 0.1s,
        opacity 0.1s linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
    text-shadow: 0 0 2px black;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
}

.home__keyboardWrapper:global(.visible) {
    opacity: 1;
    visibility: visible;
    transition:
        visibility 0s linear 0s,
        opacity 0.1s linear;
}

.ordered__hero {
    display: block;
    width: 40%;
    height: auto;
    margin-top: 50px;
}

.tracker__map {
    width: 100%;
    height: 0;
    padding-top: 100%;
    margin-top: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.tracker__map_copyright {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 11px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 3px;
}

.tracker__map_copyright_link {
    color: #333;
    text-decoration: underline;
}

.tracker__drone {
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    position: absolute;
    transition: transform 1s linear;
}

.tracker__no_drone {
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    position: absolute;
    top: 50%;
    left: 50%;
}

.title,
.subtitle {
    color: #fffec6;
    text-shadow: 0 1px 1px #e94538;
}

.title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.subtitle {
    font-size: 15px;
    opacity: 0.9;
    margin-top: 0.5em;
    text-align: center;
}

.tracker .subtitle {
    padding: 0 20px;
    height: 40px;
}
