.feed {
    position: relative;
    overflow: hidden;
}

.feed__loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feed__alert {
    position: absolute;
    z-index: 5;
    top: 10px;
    left: 10px;
    background: var(--color-feed-alert-background);
    color: var(--color-feed-alert-text);
    font-size: 12px;
    line-height: 1;
    padding: 0.5em 1em;
    border-radius: 2em;
    font-weight: 500;
    animation: alert 1s infinite ease-in-out;
}

.zoomableWrapper {
    width: unset !important;
    height: unset !important;
    overflow: visible !important;
    position: relative;
    z-index: 2;
}

.zoomableContent {
    width: unset !important;
    height: unset !important;
}

@keyframes alert {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}
