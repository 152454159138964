.imageWrapper {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.imageWrapper.loaded::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.007;
    background-image: linear-gradient(0deg, #e5e5f7 50%, #000000 50%);
    background-size: 12px 12px;
    z-index: 99;
    animation: noise1 300ms linear infinite;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.imageFragment {
    position: absolute;
    visibility: hidden;
}

.loaded .imageFragment {
    visibility: visible;
}

@keyframes noise1 {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-12px);
    }
}
