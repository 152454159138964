.matches {
    width: 100%;
    height: 100%;
    flex-grow: 0;
    overflow: hidden;
}

.matches__offline {
    font-size: 26px;
    color: #666;
    text-align: center;
    padding-top: 30px;
}

.matches__offline__icon {
    font-size: 100px;
    margin-bottom: 10px;
    color: #ddd;
}

.noMatches {
    color: #333;
    text-align: center;
    padding-top: 30px;
    font-size: 18px;
    animation: fade-in 0.5s ease-in-out;
}

.noMatches__title {
    font-weight: bold;
}

.noMatches__text {
    margin-top: 10px;
}

.matcher {
    width: 100%;
    height: 100%;
    position: relative;
}

.profile {
    width: 100%;
    height: 98%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.4);
}

.matcher__nextProfile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.matcher__swipeCard {
    position: relative;
    z-index: 2;
}

.profile__picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile__info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5%;
    padding-bottom: 75px;
    background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 10%,
        black 70%,
        black 100%
    );
}

.profile__title {
    font-size: 30px;
    color: #fff;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
    font-weight: bold;
}

.profile__title__age {
    font-weight: normal;
    margin-left: 0.4em;
}

.profile__distance {
    color: #fff;
    margin-top: 0.2em;
}

.profile__distance__icon {
    display: inline-block;
    margin-right: 0.3em;
    vertical-align: top;
}

.profile__no,
.profile__yes {
    position: absolute;
    z-index: 1;
    height: auto;
}

.profile__no {
    top: 8%;
    right: 6%;
    width: 40%;
    animation: no-animation 0.2s ease-in both;
}

.profile__yes {
    top: 8%;
    left: 4%;
    width: 50%;
    animation: yes-animation 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.controls {
    position: absolute;
    bottom: 4%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.controls__button {
    background-color: white;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.2);
    margin: 0 20px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s cubic-bezier(0.12, 0.39, 0.46, 1.77);
}

.controls__button.yes {
    color: #75eb00;
}

.controls__button.no {
    color: #ee0128;
}

.controls__button.active,
.controls__button:active {
    transform: scale(1.2);
}

.controls__button.yes.active,
.controls__button.yes:active {
    color: #000;
    background-color: #75eb00;
    border: none;
}

.controls__button.no.active,
.controls__button.no:active {
    color: #fff;
    background-color: #ee0128;
    border: none;
}

.controls__button.disabled {
    color: #000 !important;
    pointer-events: none;
}

.controls__button.inactive {
    transition: transform 0.2s ease-out;
    transform: scale(0);
}

@keyframes yes-animation {
    0% {
        transform: rotate(15deg) scale(0);
        opacity: 0;
    }

    100% {
        transform: rotate(-15deg) scale(1);
        opacity: 1;
    }
}

@keyframes no-animation {
    0% {
        transform: rotate(15deg) scale(1.5);
        opacity: 0;
    }

    100% {
        transform: rotate(15deg) scale(1);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
