.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10001;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
}

.notification {
    background-color: var(--color-chat-notification-background);
    color: var(--color-chat-notification-text);
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    max-width: 90%;
    padding: 0.8em 1.2em 0.8em 2.8em;
    line-height: 1;
    font-size: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    position: relative;
    transform: translateY(-60px);
    transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    overflow: hidden;
    pointer-events: auto;
}

.notification.entered {
    transform: translateY(0);
}

.title {
    font-weight: bold;
    margin-right: 0.5em;
}

.icon {
    width: 2.2em;
    height: 2.2em;
    position: absolute;
    top: 50%;
    left: 0.3em;
    transform: translateY(-50%);
    display: inline-block;
    vertical-align: top;
    padding: 2px;
    border-radius: 1.1em;
}
