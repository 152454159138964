.list {
    height: 100%;
    width: 100%;
    position: relative;
    font-size: var(--base-font-size);
}

.list__title {
    font-size: 1.25em;
    font-weight: bold;
    padding: 10px 10px 0;
    color: var(--color-primary-title);
}

.list__noChats {
    text-align: center;
    font-size: 20px;
    color: var(--color-gray-500);
    padding: 30px 10px;
}

.item {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item:hover {
    background-color: #fafafa;
}

.item.active {
    background-color: #eeeeee;
}

.item__pictureWrapper {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    margin: 0 10px;
    position: relative;
    background: linear-gradient(45deg, #f0f5f4, #d2d7d6);
}

.item__picture {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.item__details {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #eeeeee;
    min-width: 0; /* https://css-tricks.com/flexbox-truncated-text/ */
}

.item__title {
    position: relative;
    color: var(--color-secondary-stronger);
}

.item__time {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 15px;
    overflow: hidden;
    color: var(--color-secondary-lighter);
    font-size: 0.75em;
    line-height: 1.25em;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.item__status {
    position: relative;
    overflow: hidden;
    color: var(--color-secondary-stronger);
    font-size: 0.875em;
    line-height: 1.5em;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 42px;
    margin-top: 3px;
}

.item__status.typing {
    color: var(--color-unread-marker-background);
}

.item__status.deleted {
    color: #666;
    font-style: italic;
}

.item__status__media {
    color: var(--color-secondary);
}

.item__status__icon {
    vertical-align: text-top;
    margin-right: 0.3em;
    opacity: 0.8;
}

.item__unread {
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--color-unread-marker-text);
    background-color: var(--color-unread-marker-background);
    font-size: 12px;
    line-height: 1em;
    min-height: 1em;
    border-radius: 1.1em;
    text-align: center;
    padding: 0.3em 0.6em;
    display: inline-block;
    margin-right: 15px;
}
