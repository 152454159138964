.app {
    width: 100%;
    height: 100%;
    padding-bottom: var(--phone-footer-height);
    font-size: 16px;
    overflow: hidden;
    background-color: #fbfaff;

    --color-text-primary: #474c72;
    --color-text-secondary: #3f4572;
}

.scroller {
    overflow: auto;
    height: 100%;
}

.index {
    padding: 0 5%;
}

.index__title {
    font-size: 24px;
    line-height: 3;
    font-weight: bold;
    color: var(--color-text-primary);
}

.index__item {
    display: block;
}

.index__item.featured {
    background-color: #fff;
    border-radius: 20px;
}

.index__item.list {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
}

.index__item__image {
    position: relative;
    width: 100%;
    height: 180px;
    border-radius: 20px;
    background-size: cover;
    background-position: center center;
}

.index__item__image__playIcon {
    color: white;
    font-size: 35px;
    line-height: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.featured .index__item__image__playIcon {
    font-size: 70px;
    line-height: 70px;
}

.list > .index__item__image {
    height: 80px;
    width: 100px;
    flex-shrink: 0;
    flex-grow: 0;
}

.list > .index__item__contents {
    padding-left: 16px;
}

.index__item__title {
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
    color: var(--color-text-primary);
}

.featured .index__item__title {
    padding: 5%;
}

.index__item__unread {
    font-size: 13px;
    color: white;
    background-color: red;
    border-radius: 10px;
    margin-right: 5px;
    line-height: 20px;
    display: inline-block;
    padding: 0 5px;
    vertical-align: text-top;
}

.list .index__item__title {
    font-size: 16px;
}

.index__item__subtitle {
    font-size: 14px;
    color: var(--color-text-secondary);
    margin-top: -2.5%;
}

.featured .index__item__subtitle {
    padding: 0 5% 5%;
}

.list .index__item__subtitle {
    margin-top: 3px;
}

/****************************   */

.article__contents {
    padding: 0 5%;
    font-size: var(--base-font-size);
}

.article__image {
    width: 90%;
    margin: 5% 5% 0;
    height: auto;
    border-radius: 20px;
}

.article__video {
    margin: 5% 5% 0;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    background-color: black;
}

.article__video video {
    object-fit: cover;
}

.article__title {
    font-size: 20px;
    line-height: 1.3;
    font-weight: bold;
    margin-top: 0.7em;
    color: var(--color-text-primary);
}

.article__subtitle {
    font-size: 1.1em;
    color: var(--color-text-secondary);
    margin-top: 10px;
}

.article__body {
    line-height: 1.3;
    color: var(--color-text-primary);
}

.article__body img {
    max-width: 100%;
    height: auto;
}

.article__body a {
    color: var(--color-text-primary);
    text-decoration: underline;
}

.article__audioPlayer {
    background-color: #fff;
    border-radius: 20px;
    flex-shrink: 0;
    margin-top: 10px;
}

.article__audioPlayer__title {
    font-size: 12px;
    line-height: 1;
    padding: 10px 20px 0;
    color: var(--color-text-primary);
}

.article__audioPlayer :global(.rhap_container) {
    padding-top: 5px;
}

.article__audioPlayer :global(.rhap_play-pause-button) {
    margin-left: 0;
}

.article__audioPlayer :global(.rhap_controls-section) {
    flex: 0;
    margin-right: 0;
}

.article__audioPlayer :global(.rhap_progress-indicator) {
    background: var(--color-text-primary);
}

.article__audioPlayer :global(.rhap_main-controls-button) {
    color: var(--color-text-primary);
}

.article__audioPlayer :global(.rhap_time) {
    color: var(--color-text-primary);
    font-size: 0.8em;
}

.article__audioPlayer :global(.rhap_current-time) {
    margin-right: 5px;
    margin-left: 3px;
}

.article__audioPlayer :global(.rhap_total-time) {
    margin-left: 5px;
}
