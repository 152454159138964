@keyframes roll-in-left {
    0% {
        transform: translateX(-100px) rotate(-540deg);
        opacity: 0;
    }

    100% {
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes roll-in-right {
    0% {
        transform: translateX(100px) rotate(540deg);
        opacity: 0;
    }

    100% {
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes rotate-in-cw {
    0% {
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes scale-in {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    80% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
