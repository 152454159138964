.app {
    --color-text: #000;
    --color-title: var(--color-text);
    --color-subtitle: var(--color-text);
    --color-background: #f4f4f4;
    --color-button-background: #ff0000;
    --color-button-shadow: #b30000;
    --color-button-text: #f4f4f4;

    width: 100%;
    height: 100%;
    background: var(--color-background);
    padding: 0 20px;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 2em;
    color: var(--color-title);
}

.subtitle {
    font-size: 1.2em;
    margin-top: 0.3em;
    color: var(--color-subtitle);
    text-align: center;
    text-wrap: balance;
}

.imageButton {
    width: 250px;
    height: 250px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    margin: 30px 0;
}

.imageButton__image {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.pressed .imageButton__image {
    transform: translateX(-50%);
}

.button {
    background: var(--color-button-background);
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 1.5rem;
    padding: 40px 50px;
    cursor: pointer;
    transition: transform 0.1s ease;
    box-shadow: 0 15px var(--color-button-shadow);
}

.button.pressed {
    transform: translateY(15px);
    box-shadow: 0 6px var(--color-button-shadow);
}

.button__text {
    display: inline-block;
    transform: rotateX(20deg); /* Adjust the angle to match the button */
    transform-origin: center; /* Ensure rotation happens around the center */
    color: var(--color-button-text);
    text-shadow:
        0.5px 0.5px 1px rgba(0, 0, 0, 0.3),
        -0.5px -0.5px 1px rgba(255, 255, 255, 0.2);
}

.keypad {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition:
        visibility 0s linear 0.1s,
        opacity 0.1s linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
    text-shadow: 0 0 2px black;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
}

.keypad.visible {
    opacity: 1;
    visibility: visible;
    transition:
        visibility 0s linear 0s,
        opacity 0.1s linear;
}
