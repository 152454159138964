.app {
    --color-background: #000;
    --color-text: #fff;
    --color-index-section-name: var(--color-text);
    --color-title-info-tag: #aaa;
    --color-title-info-tag-full-background: #333;
    --color-title-info-tag-full-text: var(--color-title-info-tag);
    --color-title-info: #999;
    --color-title-offline-title: #aaa;
    --color-title-offline-text: #aaa;
    --header-height: 50px;

    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    position: relative;
    background-color: var(--color-background);
    color: var(--color-text);
}

.index {
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: 100%;
    padding: 10px;
}

.index__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    padding: 5px 10px;
    z-index: 2;
    background-color: var(--color-background);
    transition: opacity 0.3s ease-out;
}

.index__header.scrolling {
    opacity: 0.5;
}

.index__header__logo {
    display: block;
    height: 100%;
    width: auto;
}

.index__content {
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding-top: var(--header-height);
}

.section {
    width: 100%;
    margin-bottom: 20px;
    opacity: 0;
}

.section:last-child {
    margin-bottom: 0;
}

.section.entered {
    opacity: 1;
    animation: section-enter 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) both;
}

.section__name {
    font-weight: bold;
    padding-bottom: 4px;
    font-size: 16px;
    color: var(--color-index-section-name);
    margin-left: 5px;
}

.section__titles {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 5px;
}

.section__title {
    height: 130px;
    width: 90px;
    background-size: cover;
    background-position: center center;
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
    border-radius: 5px;
}

.section__title__name {
    font-size: 14px;
    color: #fff;
    text-shadow: 0 0 3px black;
    text-align: center;
    font-weight: bold;
    word-break: break-word;
}

/*********************************************   */

.title {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: var(--color-background);
    animation: title-enter 300ms ease-in-out both;
}

.title.unloading {
    animation: title-exit 300ms ease-in-out both;
}

.title__poster {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
}

.title__name {
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 3px black;
}

.title__topBar {
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.title__backButton {
    display: inline-block;
    font-size: 22px;
    color: white;
    text-shadow: 0 0 3px black;
    align-self: center;
    padding: 10px 10px 10px 20px;
}

.title__details {
    padding: 10px 18px;
}

.tagList {
    margin-top: 6px;
}

.tag {
    font-size: 14px;
    color: var(--color-title-info-tag);
    margin-right: 10px;
    padding: 5px 2px;
}

.tag.hd {
    font-size: 10px;
    color: var(--color-title-info-tag-full-text);
    background-color: var(--color-title-info-tag-full-background);
    border-radius: 4px;
    padding: 2px 3px;
    font-weight: bold;
}

.title__frields,
.title__starring {
    margin-top: 10px;
    font-size: 14px;
    color: var(--color-title-info);
}

.title__details__offline {
    margin-top: 40px;
    text-align: center;
}

.title__details__offline__title {
    font-size: 18px;
    font-weight: bold;
    color: var(--color-title-offline-title);
}

.title__details__offline__text {
    font-size: 14px;
    color: var(--color-title-offline-text);
    margin-top: 8px;
}

.description {
    margin-top: 10px;
}

@keyframes title-enter {
    from {
        opacity: 0;
        transform: scale(0.5);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes title-exit {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(2);
    }
}

@keyframes section-enter {
    0% {
        transform: translateY(15px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
