.storyAnswerContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #fff;
    background-color: transparent;
    padding: 0;
}

.textbox {
    line-height: 1;
    font-size: 18px;
    width: 100%;
    height: 3rem;
    color: #fff;
    padding-left: 1rem;
    padding-right: 2rem;
    background-color: var(--color-input-text-bg);
    border: 2px solid var(--color-input-text-border);
    border-radius: var(--container-border-radius);
    font-family: var(--font-family-default);
}

.textbox[disabled] {
    opacity: 0.5;
}

.title {
    width: 100%;
    font-family: "Roboto";
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.1;
}

.form {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}

.submitButton {
    margin-left: 10px;
}

.send_icon {
    color: #fff;
    position: relative;
    top: 3px;
    width: 26px;
}

.send_icon.loading {
    visibility: hidden;
}

.error {
    font-size: 14px;
    color: #f4364c;
    text-align: left;
    padding: 0 5px;
    margin-top: 10px;
}

.textbox__clear {
    color: #aaa;
    position: absolute;
    top: 0;
    height: 100%;
    right: 4rem;
    margin: auto;
    line-height: 3rem;
    z-index: 4;
}

.question {
    color: #fff;
    margin-top: 10px;
}
