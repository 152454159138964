.info {
    overflow: hidden;
    max-height: 5.5em;
    transition: max-height 0.3s;
    font-size: var(--base-font-size);
}

.info.hidden {
    max-height: 0;
}

.details {
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 0.9em;
    color: #1dbf73;
}

.details__contents {
    text-align: center;
    padding: 10px;
}

.details__item {
    margin-top: 5px;
    position: relative;
}

.details__item:first-child {
    margin-top: 0;
}

.visible .details__item {
    animation: fade-in 0.3s linear 0.1s both;
}

.icons {
    text-align: center;
    padding: 0 10px 10px;
}

.icon {
    display: inline-block;
    font-size: 26px;
    line-height: 1;
    vertical-align: top;
}

.icon.call {
    color: #1dbf73;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
