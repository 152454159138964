.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 5px 16px;
    background-color: var(--color-panel-header-background);
}

.footer__input_line {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    background-color: var(--color-panel-header-background);
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer__input {
    padding: 9px 12px 11px;
    background-color: var(--color-compose-input-background);
    border: 1px solid var(--color-compose-input-border);
    border-radius: 21px;
    flex-grow: 1;
    font-size: 15px;
    line-height: 20px;
}

.footer__sendButton {
    font-size: 24px;
    line-height: 24px;
    color: var(--color-icon);
    cursor: pointer;
    padding-left: 8px;
    transition: color 0.2s;
}

.footer__sendButton.disabled {
    pointer-events: none;
    color: var(--color-icon-disabled);
}

.footer__options_list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.footer__option_item {
    position: relative;
    border-radius: 7.5px;
    margin-bottom: 2px;
    padding: 6px 9px 6px 8px;
    box-shadow: 0 1px 0.5px rgba(var(--color-shadow-rgb), 0.13);
    max-width: 85%;
    color: var(--color-chat-outgoing-text);
    background-color: var(--color-chat-outgoing-background);
    cursor: pointer;
    text-align: center;
}

.footer__option_item:not(:first-child) {
    margin-left: 5px;
}
