.app {
    --color-text: #eee;
    --color-title-text: #eee;
    --color-background: #000;
    --color-background-secondary: #333;
    --font-size-title: 32px;

    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
    width: 100%;
    height: 100%;
    font-size: var(--base-font-size);
    overflow: hidden;
    padding-bottom: var(--phone-footer-height);
}

.header {
    position: relative;
    background-color: var(--color-background);
    font-size: var(--font-size-title);
    color: var(--color-title-text);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    z-index: 1;
    height: 138px;
}

.header__background {
    position: absolute;
    top: -20px;
    width: 100%;
    object-fit: contain;
    z-index: 0;
}

.header__text {
    z-index: 1;
}

.header__icon {
    width: calc(var(--font-size-title) * 1.3);
    height: calc(var(--font-size-title) * 1.3);
    margin-right: 0.25em;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
}

.header__icon__image {
    width: 100%;
    height: 100%;
}

.noPhotos {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noPhotos__title {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-text);
}

.index__scroller {
    overflow: auto;
    height: 100%;
    padding: 0 3px;
}

.section:not(:first-child) {
    margin: 1em 0;
}

.section__name {
    color: var(--color-text);
    font-size: 1.5em;
    padding: 1em 0.5em 0.5em;
}

.photoGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3px;
}

.photoGrid__thumb {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--color-background-secondary);
}

.photoGrid__thumb::before {
    content: " ";
    display: block;
    width: 100%;
    padding-top: 100%;
}

.photoGrid__thumb__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.photoGrid__thumb__play {
    color: white;
    font-size: 50px;
    line-height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    z-index: 10;
}

.photoDetails {
    position: relative;
    width: 100%;
    height: 100%;
}

.photoDetails__header {
    position: absolute;
    width: 100%;
    z-index: 100;
    display: flex;
}

.photoDetails__header__backBtn {
    display: flex;
    font-size: 25px;
    color: var(--color-text);
    padding: 5px 10px;
}

.photoDetails :global(.react-transform-component) {
    overflow: visible;
}

.photoDetails__photoWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    overflow: hidden;
}

.photoDetails__info {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: center;
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    letter-spacing: 1px;
    text-shadow:
        0.05em 0 rgba(0, 0, 0, 0.5),
        0 0.05em rgba(0, 0, 0, 0.5),
        -0.05em 0 rgba(0, 0, 0, 0.5),
        0 -0.05em rgba(0, 0, 0, 0.5),
        -0.05em -0.05em rgba(0, 0, 0, 0.5),
        -0.05em 0.05em rgba(0, 0, 0, 0.5),
        0.05em -0.05em rgba(0, 0, 0, 0.5),
        0.05em 0.05em rgba(0, 0, 0, 0.5);
}

.photoDetails__photo {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.photoDetails__video {
    width: 100%;
    height: auto;
}

.photoDetails__photo__zoomTutorial {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.1s;
}

.photoDetails__photo__zoomTutorial.visible {
    opacity: 1;
}

.photoDetails__photo__zoomTutorial__container {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: hover 2s infinite;
}

.video {
    width: 100%;
    height: 100%;
    position: relative;
}

.video__loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes hover {
    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    50% {
        transform: translate3d(-5px, -5px, 0);
    }
}
