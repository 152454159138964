.message__container {
    display: flex;
    position: relative;
}

.message__container.in {
    justify-content: flex-start;
    padding-left: 30px;
}

.message__container.out {
    justify-content: flex-end;
}

.message__container.extraSpace {
    margin-top: 8px;
}

.message__profile_Image {
    width: 25px;
    height: 25px;
    border-radius: 14px;
    background-size: cover;
    background-position: center;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 3px;
    position: absolute;
    top: 0;
    left: 0;
}

.message__profile_Image.hidden {
    visibility: hidden;
}

.message {
    position: relative;
    border-radius: 7.5px;
    margin-bottom: 2px;
    padding: 6px 9px 6px 8px;
    box-shadow: 0 1px 0.5px rgba(var(--color-shadow-rgb), 0.13);
    max-width: 85%;
}

.message.in {
    align-self: flex-start;
    color: var(--color-chat-incoming-text);
    background-color: var(--color-chat-incoming-background);
}

.message.out {
    align-self: flex-end;
    color: var(--color-chat-outgoing-text);
    background-color: var(--color-chat-outgoing-background);
}

.message.extraSpace {
    margin-top: 12px;
}

.message.system {
    align-self: center;
    background-color: var(--color-chat-system-background);
    color: var(--color-chat-system-text);
    padding: 10px 16px;
    text-align: center;
}

.message.audio {
    min-width: 200px;
}

.message.error {
    margin-bottom: 18px;
}

.from {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.8em;
    font-weight: bold;
}

.from.c1 {
    color: var(--color-chat-from-text-1);
}

.from.c2 {
    color: var(--color-chat-from-text-2);
}

.from.c3 {
    color: var(--color-chat-from-text-3);
}

.from.c4 {
    color: var(--color-chat-from-text-4);
}

.from.c5 {
    color: var(--color-chat-from-text-5);
}

.from.c6 {
    color: var(--color-chat-from-text-6);
}

.from.c7 {
    color: var(--color-chat-from-text-7);
}

.from.c8 {
    color: var(--color-chat-from-text-8);
}

.from.c9 {
    color: var(--color-chat-from-text-9);
}

.from.c10 {
    color: var(--color-chat-from-text-10);
}

.text {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 0.9em;
}

.text.deleted {
    color: #999;
    font-style: italic;
}

.text.typing {
    padding: 0 2px;
}

.text.emoji {
    font-size: 1.875em;
    text-align: center;
}

.text__contents {
    display: inline;
}

.text__contents > p {
    margin-bottom: 0;
}

/* white-space: prewrap takes care of line breaks.
<br> tags create duplicate line breaks, hence hidden */
.text__contents br {
    display: none;
}

.text__contents > p:last-child {
    display: inline;
}

.text__contents > p:first-child {
    margin-top: 0;
}

.text__contents a {
    color: #039be5;
}

.image {
    max-width: 100%;
    max-height: 330px;
    display: block;
    margin-bottom: 2px;
    border-radius: 6px;
}

.videoThumbail {
    position: relative;
    cursor: pointer;
}

.videoThumbail__image {
    max-width: 200px;
    max-height: 200px;
}

.videoThumbail__icon {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    font-size: 40px;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 30px;
    color: var(--color-chat-video-thumb-play-icon);
    background-color: var(--color-chat-video-thumb-play-background);
}

.text__spacer {
    width: 60px;
    line-height: 1;
    display: inline-block;
    vertical-align: top;
}

.emoji .text__spacer {
    width: 54px;
}

.out .text__spacer {
    width: 65px;
}

.time {
    position: absolute;
    bottom: 2px;
    right: 6px;
    font-size: 0.6875em;
    color: var(--color-bubble-meta);
}

.status__icon {
    margin-left: 3px;
    vertical-align: text-top;
}

.status__icon.error {
    color: var(--color-icon-error);
}

.notDelivered {
    position: absolute;
    bottom: -16px;
    right: 3px;
    font-size: 0.75em;
    color: var(--color-icon-error);
}

.transcript {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    width: 240px;
}

.transcript__text {
    font-size: 0.9em;
}

.transcript__showButton {
    font-size: 0.8em;
    text-align: center;
    padding: 0.5em 0;
}

.transcript__showButton__icon {
    vertical-align: top;
    margin-right: 0.2em;
}
