.app {
    --color-app-background: #fff;
    --color-bubble-meta: rgba(0, 0, 0, 0.45);
    --color-compose-input-background: #fff;
    --color-compose-input-border: #fff;
    --color-gray-500: grey;
    --color-icon: #06d755;
    --color-icon-disabled: #aaa;
    --color-icon-error: #e40000;
    --color-panel-header-background: #ededed;
    --color-primary-strong: #000;
    --color-primary-title: #525252;
    --color-shadow-rgb: 0, 0, 0;
    --color-secondary-lighter: rgba(0, 0, 0, 0.45);
    --color-secondary-stronger: rgba(0, 0, 0, 0.8);
    --color-secondary: rgba(0, 0, 0, 0.6);
    --color-unread-marker-background: var(--color-icon);
    --color-unread-marker-text: rgba(0, 0, 0, 0.7);
    --color-chat-incoming-background: #fff;
    --color-chat-incoming-text: #000;
    --color-chat-outgoing-background: #dcf8c6;
    --color-chat-outgoing-text: #000;
    --color-chat-system-background: #fff4c4;
    --color-chat-system-text: #635f4d;
    --color-chat-video-thumb-play-icon: #fff;
    --color-chat-video-thumb-play-background: rgba(0, 0, 0, 0.3);
    --color-chat-notification-background: rgba(220, 220, 220);
    --color-chat-notification-text: #333;
    --color-chat-new-messages-mark: #cc0000;
    --color-chat-from-text-1: #fe7312;
    --color-chat-from-text-2: #d85395;
    --color-chat-from-text-3: #039be5;
    --color-chat-from-text-4: #75a495;
    --color-chat-from-text-5: #ffd7e2;
    --color-chat-from-text-6: #ffeb4a;
    --color-chat-from-text-7: #ff4db9;
    --color-chat-from-text-8: #eb0450;
    --color-chat-from-text-9: #7a0406;
    --color-chat-from-text-10: #039be5;

    overflow: hidden;
    width: 100%;
    height: 100%;
    padding-bottom: var(--phone-footer-height);
    background-color: var(--color-app-background);
}
