.app {
    --color-background: #eff2fb;
    --color-title: #000;
    --color-card-background: #1c1c1e;
    --color-card-text: #fff;
    --color-card-image-text: #fff;
    --color-dish-border: rgba(255, 255, 255, 0.8);
    --color-dish-button-background: #fff;
    --color-dish-button-text: #000;
    --color-active-deliveries-background: var(--color-background);
    --color-active-deliveries-border: #999;
    --color-active-deliveries-title: var(--color-title);
    --color-active-delivery-title: #fff;
    --color-active-delivery-progress-trail: rgba(0, 0, 0, 0.1);
    --color-active-delivery-progress-bar: rgba(0, 0, 0, 0.4);

    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    background-color: var(--color-background);
    position: relative;
}

.home {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.header {
    padding: 20px 15px 15px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    flex-grow: 0;
    flex-shrink: 0;
    color: var(--color-title);
}

.dish {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid var(--color-dish-border);
}

.dish:not(:first-child) {
    margin-top: 10px;
}

.dish__image {
    flex-shrink: 0;
    flex-grow: 0;
    width: 40%;
    object-fit: cover;
}

.dish__details {
    width: 100%;
    padding: 10px;
    font-size: 14px;
}

.dish__title {
    font-weight: bold;
}

.dish__description {
    margin-top: 10px;
}

.dish__orderButton {
    margin-top: 10px;
    font-weight: bold;
    background: var(--color-dish-button-background);
    color: var(--color-dish-button-text);
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.dish__orderButton.disabled {
    opacity: 0.7;
    pointer-events: none;
}

.activeDeliveries {
    position: absolute;
    left: 0;
    bottom: var(--phone-footer-height);
    transform: translateY(calc(100% - 30px));
    z-index: 20;
    background-color: var(--color-active-deliveries-background);
    width: 100%;
    transition: transform 0.3s ease-in-out;
    border-top: 1px solid var(--color-active-deliveries-border);
}

.activeDeliveries.open {
    transform: translateY(0);
}

.activeDeliveries__title {
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-weight: bold;
    color: var(--color-active-deliveries-title);
}

.activeDeliveries__list {
    max-height: 200px;
    overflow: auto;
}

.activeDelivery {
    margin: 10px 20px 0;
    opacity: 0;
    position: relative;
}

.activeDelivery:last-child {
    margin-bottom: 10px;
    height: 30px;
}

.open .activeDelivery {
    opacity: 1;
}

.activeDelivery__title {
    font-size: 14px;
    line-height: 30px;
    padding-left: 10px;
    color: var(--color-active-delivery-title);
    position: relative;
    z-index: 3;
}

.activeDelivery__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-active-delivery-progress-trail);
    border-radius: 20px;
    overflow: hidden;
    z-index: 1;
}

.activeDelivery__progress__bar {
    background-color: var(--color-active-delivery-progress-bar);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: width 1s linear;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    z-index: 2;
}
