.app {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: var(--phone-footer-height);
}

.list {
    overflow: auto;
}

.title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    flex-shrink: 0;
}

.map {
    width: 100%;
    height: 200px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    flex-shrink: 0;
}

.entry {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0;
}

.entry__img {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 25px;
    border: 0.3px solid rgb(120, 120, 120);
    object-fit: cover;
}

.entry__details {
    margin-left: 10px;

    /* padding-bottom: 10px; */
    border-bottom: 1px solid #eee;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.entry:last-child .entry__details {
    border-bottom: none;
}

.entry__name {
    font-size: 18px;
    color: #333;
}

.entry__name.selected {
    font-weight: bold;
}

.entry__location {
    font-size: 14px;
    margin: 5px 0;
    color: #999;
}
