.actionButton {
    position: relative;
}

.actionButton__icon {
    width: 40px;
    height: auto;
    cursor: pointer;
    user-select: none;
}
