.app {
    --color-background: #eff2fb;
    --color-header-background: transparent;
    --color-text: #000;
    --color-title: var(--color-text);
    --color-item-details-background: rgba(255, 255, 255, 0.8);
    --color-item-details-text: #000;
    --color-textbox-background: #77787a;
    --color-textbox-text: #fff;
    --color-textbox-placeholder: var(--color-textbox-text);
    --color-button-background: var(--color-textbox-background);
    --color-button-text: var(--color-textbox-text);
    --color-travel-background: var(--color-background);
    --color-travel-text: var(--color-text);
    --color-travel-border: var(--color-travel-text);

    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
}

.header {
    width: 100%;
    position: relative;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: var(--color-header-background);
}

.header__title,
.header__done {
    color: var(--color-title);
}

.header__title {
    font-weight: bold;
    font-size: 26px;
    line-height: 1;
}

.header__done {
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 16px;
    transition:
        transform 0.2s ease-in-out,
        opacity 0.2s ease-in-out;
}

.header__done.hidden {
    pointer-events: none;
    transform: translateX(-150%);
    opacity: 0;
}

.header__done__icon {
    vertical-align: top;
}

.loginScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
}

.textboxForm {
    margin-top: 10px !important;
}

.textbox {
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: var(--color-textbox-background);
    color: var(--color-textbox-text);
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 30px;
    flex-shrink: 0;
}

.textbox:not(:first-child) {
    margin-top: 10px;
}

.textbox::placeholder {
    color: var(--color-textbox-placeholder);
}

.textbox.disabled {
    opacity: 0.5;
}

.submitButton {
    font-size: 16px;
    background: var(--color-button-background);
    color: var(--color-button-text);
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    display: inline-block;
    margin-top: 10px;
    width: 100%;
    flex-shrink: 0;
    text-align: center;
    font-weight: bold;
    transition: opacity 0.1s ease-in-out;
}

.submitButton.disabled {
    pointer-events: none;
    opacity: 0.7;
}

.travel {
    height: 100%;
    width: 100%;
    background: var(--color-travel-background);
    color: var(--color-travel-text);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}

.travel__details {
    width: 100%;
    line-height: 1.8;
    border: 1px solid var(--color-travel-border);
    padding: 15px;
}

.travel__details__status {
    font-size: 12px;
    font-weight: bold;
}

.travel__details__title {
    font-size: 22px;
}

.travel__details__subtitle {
    font-weight: 500;
}

.travel__details__fields {
    margin-top: 10px;
}

.travel__details__price {
    text-align: right;
    border-top: 1px solid var(--color-travel-border);
    padding-top: 10px;
    font-size: 18px;
}

.travel__field__title {
    font-weight: bold;
}

.button {
    font-size: 16px;
    background: var(--color-button-background);
    color: var(--color-button-text);
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    display: inline-block;
    margin-top: 10px;
    width: 100%;
    flex-shrink: 0;
    text-align: center;
    font-weight: 500;
    transition: opacity 0.1s ease-in-out;
}
