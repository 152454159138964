.storySidePanel {
    --color-free: #e51bf7;

    position: absolute;
    z-index: var(--zindex-hint-button);
    bottom: 150px;
    right: 0;
    width: fit-content;
    justify-content: space-around;
    padding: 5px 0;
    border-radius: 10px 0 0 10px;
    background:
        linear-gradient(rgba(0, 11, 21, 0.4) 0%, rgba(0, 11, 21, 0.2) 100%)
            right bottom no-repeat,
        /* radial-gradient(rgba(0, 11, 21, 0.4) 0%, rgba(0, 11, 21, 0.2) 100%), */
            radial-gradient(
                circle at 100%,
                rgba(204, 252, 255, 0.2) 0%,
                rgba(23, 138, 146, 0.1) 100%
            );
}

.icon__brief {
    position: relative;
    top: 6px;
}

.icon__hints__v2 {
    /* Without !important it will be override by actionButton__icon class */
    width: 34px !important;
    left: 3px;
}

.divider {
    height: 2px;
    width: 70%;
    border-radius: 1px;
    background: rgba(115, 184, 189, 0.5);
    margin: 5px auto;
}

.dots {
    width: 60%;
    margin: auto;
    pointer-events: none;
}
