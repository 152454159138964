.screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--zindex-full-screen-animation);
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.screen.entered {
    opacity: 1;
}

.screen.exiting {
    opacity: 0 !important;
}
