.control {
    margin-top: 20px !important;
}

.commandsToggle {
    font-size: 14px;
    text-decoration: underline;
    margin: 5px 0 0 5px;
}

.commands {
    margin-left: 5px;
}

.commands__command {
    margin-top: 0.5em;
}

.command__command__title {
    opacity: 0.8;
    font-weight: bold;
}
