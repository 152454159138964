.locker {
    --color-background: #000;
    --color-key-white: #fff;
    --color-key-black: #222;
    --color-key-active: linear-gradient(to left, #f27176 0%, #f67e83 100%);
    --color-key-label-text: rgba(0, 0, 0, 0.8);
    --color-key-label-bg-scale-1: #f7c8ac;
    --color-key-label-bg-scale-2: #ffd1dc;
    --color-key-label-bg-scale-3: #e0bbe4;
    --color-key-label-bg-scale-4: #a2d5f2;
    --color-key-label-bg-scale-5: #c3f1a9;
    --color-key-label-bg-scale-6: #ffdab9;
    --color-key-label-bg-scale-7: #e6e6fa;
    --color-key-label-bg-scale-8: #fffacd;
    --color-key-label-bg-scale-9: #d8bfd8;
    --color-key-label-bg-scale-10: #f4cccc;

    width: 100%;
    height: 100%;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
}

.piano {
    width: 100%;
    height: 100%;
    background-color: var(--color-background);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    overflow: auto;
    border-bottom: 1px solid var(--color-background);
}

.key {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 1px 0 1px 1px;
    background: var(--color-key-white);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    transform-origin: right center;
    z-index: 1;
    flex-shrink: 0;
    flex-grow: 0;
}

.key.black {
    width: 60%;
    height: 30px;
    margin: -15px 0;
    z-index: 2;
    background: var(--color-key-black);
    border: 1px solid var(--color-background);
}

.key:first-child {
    margin-top: 0;
}

.key:last-child {
    margin-bottom: 0;
}

.key.active {
    background: var(--color-key-active);
}

.exiting .key {
    animation: key-exiting 0.5s both ease-in;
}

.key__label {
    position: absolute;
    left: 10px;
    top: 50%;
    font-size: 12px;
    padding: 0.1em 0.5em;
    transform: rotate(90deg) translateX(-50%);
    text-transform: uppercase;
    color: var(--color-key-label-text);
    border-radius: 2px;
    line-height: 1;
}

.scale1 .key__label {
    background-color: var(--color-key-label-bg-scale-1);
}

.scale2 .key__label {
    background-color: var(--color-key-label-bg-scale-2);
}

.scale3 .key__label {
    background-color: var(--color-key-label-bg-scale-3);
}

.scale4 .key__label {
    background-color: var(--color-key-label-bg-scale-4);
}

.scale5 .key__label {
    background-color: var(--color-key-label-bg-scale-5);
}

.scale6 .key__label {
    background-color: var(--color-key-label-bg-scale-6);
}

.scale7 .key__label {
    background-color: var(--color-key-label-bg-scale-7);
}

.scale8 .key__label {
    background-color: var(--color-key-label-bg-scale-8);
}

.scale9 .key__label {
    background-color: var(--color-key-label-bg-scale-9);
}

.scale10 .key__label {
    background-color: var(--color-key-label-bg-scale-10);
}

@keyframes key-exiting {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}
