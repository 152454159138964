.app {
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: 50px;
    overflow: hidden;
}

.index__scroller {
    overflow: auto;
    height: 100%;
}

.index__header {
    text-align: center;
    padding: 20px 10px;
}

.index__title {
    font-size: 24px;
}

.index__docs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding: 0 15px;
}

.index__doc {
    display: block;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}

.index__doc__contents {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 15px 15px 0;
    width: 100%;
    overflow: hidden;
}

.index__doc__title {
    width: 90%;
    margin-left: 5%;
    font-size: 15px;
    position: relative;
    color: #333;
    text-align: center;
    margin-top: 0.5em;
}

.index__doc__text {
    background-color: #fff;
    color: #666;
    font-size: 8px;
    padding: 10px 10px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 70px;
    overflow: hidden;
    box-shadow: 0 -1px 7px rgba(0, 0, 0, 0.3);
}

.index__doc__title + .index__doc__text {
    margin-top: 10px;
}

.index__doc__text > p {
    margin: 0;
}

.index__doc__text > p > a {
    color: #666;
    pointer-events: none;
    text-decoration: none;
}

/**************************************   */

.doc {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.doc__topBar {
    height: 48px;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    /* padding: 10px 20px; */
}

.doc__backButton {
    display: inline-block;
    font-size: 22px;
    color: #333;
    align-self: center;
    height: 100%;
    padding: 10px 10px 10px 20px;
}

.doc__backButton__icon {
    vertical-align: top;
}

.doc__content {
    height: 100%;
    padding: 20px;
    overflow: auto;
}

.doc__title {
    font-weight: bold;
    font-size: 16px;
}

.doc__text {
    font-size: 14px;
    line-height: 1.5;
}

.doc__text > p:first-child {
    margin-top: 0;
}

.doc__text > p:last-child {
    margin-bottom: 0;
}
