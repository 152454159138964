.wrapper {
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.note {
    pointer-events: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--zindex-note);
    background-color: #fef4ad;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.3);
    opacity: 0.98;
    width: 200px;
    height: 200px;
}

.handle {
    width: 100%;
    height: 40px;
    line-height: 30px;
    background-color: #f5e899;
    border-bottom: 10px solid #fef4ad;
    text-align: center;
    font-weight: bold;
}

.move,
.close {
    position: absolute;
    top: 0;
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 18px;
    text-align: center;
}

.move {
    left: 0;
    color: rgba(0, 0, 0, 0.5);
}

.textbox {
    width: 100%;
    height: calc(100% - 30px);
    border: none;
    background-color: transparent;
    padding: 0 13px 10px;
    font-size: 15px;
    color: #333;
}

.close {
    right: 0;
}
