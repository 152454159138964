.activeCall {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: var(--zindex-phone-call);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    padding-bottom: calc(50px + min(var(--safe-area-inset-bottom), 50px));
    background: linear-gradient(
        30deg,
        rgba(43, 19, 60, 1) 0%,
        rgba(136, 18, 18, 1) 50%,
        rgba(113, 81, 35, 1) 100%
    );
}

.activeCall__title {
    font-size: 30px;
    font-weight: bold;
}

.callButton {
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    vertical-align: top;
    font-size: 30px;
    line-height: 1;
    padding: 12px 12px 11px;
    border-radius: 30px;
    color: #fff;
}

.callButton.start {
    background-color: #1dbf73;
}

.callButton.end {
    background-color: #ef3248;
}

.callButton.end::before {
    transform: rotate(135deg) translate(1px, 0);
}

.subtitlesIcon {
    position: absolute;
    left: calc(50% + 60px);
}
