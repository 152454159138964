.app {
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;

    --color-background: transparent;
    --color-title: #2b2439;
    --color-card-background: #000;
    --color-video-title-text: #fff;
    --color-video-header-background: rgba(0, 0, 0, 0.1);
    --color-transcript-button: var(--color-video-title-text);
    --color-transcript-background: #ecf0f1;
    --color-transcript-text: #000;
    --color-play-button-background: rgba(0, 0, 0, 0.5);
    --color-play-button-icon: #fff;

    color: var(--color-text);
    background: var(--color-background);
}

.scroller {
    overflow: auto;
    height: 100%;
}

.videoList {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 5px 0; /* leave room for highlight */
}

.videoList__title {
    font-size: 24px;
    font-weight: bold;
    margin: 0.75em 0;
    color: var(--color-title);
}

.videoCard {
    height: 200px;
    width: 80%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 20px;
}

.videoCard.first {
    margin-top: 0;
}

.videoCard__content__container {
    width: 100%;
    height: 100%;
    display: block;
    pointer-events: none;
}

.videoCard__content__container.open {
    top: 0;
    bottom: var(--phone-footer-height);
    left: 0;
    right: 0;
    height: auto;
    position: absolute;
    z-index: 1;
    overflow: hidden;
}

.videoCard__content {
    pointer-events: auto;
    position: relative;
    background: var(--color-card-background);
    color: var(--color-card-text);
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.videoCard__content__children {
    color: var(--color-card-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.videoCard__poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity;
}

.open .videoCard__poster {
    opacity: 0;
    pointer-events: none;
}

.videoContainer__video video {
    object-fit: cover;
}

.videoContainer__spinner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: var(--color-play-button-background);
    color: var(--color-play-button-icon);
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoCard__header {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    background: var(--color-video-header-background);
    width: 100%;
    padding: 0.75em 1em;
}

.open .videoCard__header {
    bottom: auto;
    top: 0;
}

.videoCard__header__title {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    color: var(--color-video-title-text);
}

.videoCard__playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    color: #fff;
    border: 3px solid currentcolor;
    border-radius: 50%;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.5);
}

.videoCard__playIcon__icon {
    display: block;
    transform: translateX(8%);
}

.transcript {
    position: absolute;
    top: calc(50% - 0.5em);
    right: 1em;
    font-size: 14px;
    line-height: 1;
}

.transcript__button {
    color: var(--color-transcript-button);
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.transcript__text {
    position: absolute;
    top: 150%;
    right: 0;
    background: var(--color-transcript-background);
    color: var(--color-transcript-text);
    padding: 1em;
    min-width: 240px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    animation: slide-in-right 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.transcript__text > p:first-child {
    margin-top: 0;
}

.transcript__text > p:last-child {
    margin-bottom: 0;
}

@keyframes slide-in-right {
    0% {
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
