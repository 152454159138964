.screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon.animation_spin_ccw,
.icon.animation_spin_cw {
    animation: spin 4s linear infinite;
}

.icon.animation_spin_ccw {
    animation-direction: reverse;
}

.title {
    color: #fff;
    margin-top: 2em;
    font-size: 20px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
