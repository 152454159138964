.screen {
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1;
    position: relative;
    z-index: 1;
    padding-top: 20px;
    box-shadow: -10px 0 25px -6px rgba(0, 0, 0, 0.7);

    --phone-footer-height: 50px; /* Chrome < 79 */
    /*  stylelint-disable-next-line declaration-block-no-duplicate-custom-properties  */
    --phone-footer-height: calc(
        50px + min(var(--safe-area-inset-bottom), 30px)
    );
    --phone-screen-zindex-notifications: 4;
    --phone-screen-zindex-app: 3;
    --phone-screen-zindex-home-screen: 2;
}

:global(.notch90) .screen {
    padding-top: 60px;
}

:global(.notch67) .screen {
    padding-top: 40px;
}

.content {
    width: 100%;
    height: 100%;
}

.content.menuOpen {
    pointer-events: none;
}
