.app {
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
}

.scroller {
    overflow: auto;
    height: 100%;
    border-radius: 20px;
}

.list__header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.list__title {
    font-size: 22px;
}

.segment:not(:first-child) {
    margin-top: 7px;
}

.segment:not(:last-child) {
    padding-bottom: 10px;
}

.segment__title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.9);
    padding: 0 10px 7px;
}

.segment__contacts {
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 20px;
}

.contact {
    font-size: var(--base-font-size);
}

.contact__data {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    align-items: center;
}

.contact__iconWrapper {
    display: flex;
    align-items: center;
    margin: 0 10px;
    transform-origin: top left;
    transition: transform 0.3s ease-in-out;
}

.open .contact__iconWrapper {
    transform: scale(1.5);
}

.contact__details {
    padding: 15px 0;
    margin-right: 10px;
    flex-grow: 1;
    position: relative;
    transition: transform 0.3s ease-in-out;
}

.open .contact__details {
    transform: translateX(30px) scale(1.1);
}

.contact:not(:first-child) .contact__details {
    border-top: 1px solid #eee;
}

.contact:last-child .contact__details {
    border-bottom: none;
}

.contact__name {
    width: 100%;
    font-size: 0.95em;
    position: relative;
    color: #333;
}

.contact__expandIcon {
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 16px;
    color: #999;
    margin-right: 20px;
    transition: transform 0.3s ease-in-out;
}

.open .contact__expandIcon {
    transform: rotate(180deg);
}
