.footer {
    --footer-padding-bottom: calc(var(--phone-footer-height) - 50px);
    --footer-button-dark-color: #777;
    --footer-button-dark-shadow: rgba(255, 255, 255, 0.3);
    --footer-button-light-color: #fff;
    --footer-button-light-shadow: rgba(0, 0, 0, 0.4);

    width: 100%;
    height: 50px;
    height: var(--phone-footer-height);
    padding-bottom: var(--footer-padding-bottom);
    position: absolute;
    z-index: var(--zindex-phone-footer);
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.lightButtons {
    background: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.2) 100%);
}

.button {
    font-size: 18px;
    width: 1em;
    height: 1em;
    line-height: 1;
    padding: 10px;
    box-sizing: content-box;
    transition: color 0.1s;
    color: var(--footer-button-dark-color);
}

.lightButtons .button {
    color: var(--footer-button-light-color);
}

.button__icon {
    display: inline-block;
    vertical-align: top;
}

.button__icon.back {
    margin-top: -5px;
}

.settingsIcon {
    width: 20px;
    height: 20px;
    margin-top: -1px;
}

.settingsIcon,
.button__icon {
    filter: drop-shadow(0 0 3px var(--footer-button-dark-shadow));
}

.lightButtons .button__icon,
.lightButtons .settingsIcon {
    filter: drop-shadow(0 0 3px var(--footer-button-light-shadow));
}

.homeButton {
    padding: 13px 20px 12px;
    display: inline-block;
}

.homeButton::before {
    content: "";
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    border-radius: 6px;
    border: 2px solid var(--footer-button-dark-color);
    box-shadow:
        0 0 3px var(--footer-button-dark-shadow),
        inset 0 0 3px var(--footer-button-dark-shadow);
    transition: border-color 0.1s;
}

.lightButtons .homeButton::before {
    border-color: var(--footer-button-light-color);
    box-shadow: 0 0 3px var(--footer-button-light-shadow);
}
