.homeScreen {
    height: 100%;
    padding-bottom: var(--phone-footer-height);
    position: relative;
    z-index: var(--phone-screen-zindex-home-screen);

    --icon-size: 50px;
}

.homeScreen.position-bottom {
    display: flex;
    align-items: flex-end;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}

.backgroundImage.v1 {
    object-position: top center;
}

.apps {
    position: relative;
    z-index: 2;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 10px;
    gap: 0 10px;
}

.app {
    text-align: center;
    text-decoration: none;
    padding: 20px 0;
    font-size: var(--base-font-size);
    position: relative;
}

:global(.no-flex-gap) .app {
    margin: 0 5px;
}

.app__iconWrapper {
    --border-radius: calc(var(--icon-size) / 2.77777);

    position: relative;
    display: inline-block;
    width: var(--icon-size);
    height: var(--icon-size);
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 18px;
    border-radius: var(--border-radius);
}

.app__iconWrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px dashed rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    border-radius: inherit;
    z-index: 0;
}

.app__iconWrapper.loaded {
    background-color: transparent;
}

.app__iconWrapper.loaded::before {
    display: none;
}

.app__notifCounter {
    position: absolute;
    top: -5px;
    right: -7px;
    z-index: 10;
    background-color: #fe0000;
    color: white;
    font-size: 14px;
    line-height: 18px;
    border-radius: 12px;
    padding: 0 4px;
    min-width: 22px;
    text-align: center;
}

.app__icon {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    z-index: 2;
    filter: drop-shadow(0.3px 0.4px 0.8px rgba(0, 0, 0, 0.314));
}

.app__name {
    margin-top: 0.1875em;
    font-size: 0.875em;
    color: white;
    text-shadow: 0 0 8px #333;
    text-decoration: none;
}

@media screen and (width <= 320px) {
    .homeScreen {
        --icon-size: 45px;
    }

    .apps {
        padding: 0 5px;
        gap: 0 5px;
    }

    .app {
        padding: 10px 0;
    }

    :global(.no-flex-gap) .app {
        margin: 0 2px;
    }

    .app__name {
        font-size: 0.8em;
    }
}
