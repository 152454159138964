.backgroundImage {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -5%;
}

.backgroundImage.darkBlue {
    width: 160%;
    height: 160%;
    top: -30%;
}

.imageContainer {
    width: 100%;
    overflow: visible;
}

.imageContainer > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.secondaryButtonText {
    font-family: var(--font-family-title);
    margin-bottom: 5px;
    margin-top: 15px;
}

.threeImagesContainer {
    position: relative;
    width: 83%;
    height: 185px;
    margin-bottom: 1rem;
    margin-top: 2rem;
    overflow: visible;
}

.threeImages {
    height: 100%;
    margin: auto;
}

.threeImages > img {
    height: fit-content;
    max-width: 115px;
    margin: auto;
}

.threeImages > img:not(:last-child) {
    padding-bottom: 5px;
}

.handIcon {
    height: 20%;
    position: absolute;
    animation-name: hand-icon-animation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

.image__enlarged {
    transform: scale(1.25);
}

.text__bold {
    margin-top: -10px;
    -webkit-text-stroke: 1px white;
}

.button {
    margin-top: 20px !important;
}

@keyframes hand-icon-animation {
    0% {
        top: 8%;
    }

    25% {
        top: 45%;
    }

    50% {
        top: 80%;
    }

    75% {
        top: 45%;
    }

    100% {
        top: 8%;
    }
}
