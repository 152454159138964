.preview {
    width: 40%;
    height: 100%;
    flex-shrink: 0;
    flex-grow: 0;
}

.preview__actions {
    margin-top: 10px;
}

@media screen and (width <= 600px) {
    .preview {
        width: 100%;
    }
}
