.button {
    display: inline-block;
}

.button.web {
    margin-top: 10px;
}

.button.googlePlay {
    max-width: 220px;
}

.button.appStore {
    margin-top: 20px;
    width: 180px;
}

.image {
    width: 100%;
}
