.app {
    --color-background-image: linear-gradient(#000, #1e2042, #30325f);
    --color-background: #1e2042;
    --color-top-bar: #fff;
    --color-folder-item-list-background: rgba(255, 255, 255, 0.8);
    --color-folder-item-name-list: #333;
    --color-folder-item-name-grid: #fff;
    --color-file-text: #fff;
    --color-file-text-background: rgba(0, 0, 0, 0.8);
    --color-progress-text: #fff;
    --color-progress-overlay-background: rgba(255, 255, 255, 0.1);
    --color-progress-container-primary: rgba(0, 0, 0, 0.2);
    --color-progress-container-secondary: rgba(255, 255, 255, 0.1);
    --color-progress-bar-background: linear-gradient(90deg, #00c6ff, #0072ff);
    --color-progress-bar-box-shadow: rgba(0, 114, 255, 0.5);
    --color-audio-player-progress: #734695;
    --color-audio-player-background: #b874ec;
    --color-audio-player-primary: #fff;
    --color-audio-transcript-background: rgba(255, 255, 255, 0.3);
    --color-audio-transcript-text: #fff;

    height: 100%;
    padding-bottom: var(--phone-footer-height);
    background-color: var(--background);
    background-image: var(--color-background-image);
    background-size: cover;
    background-position: center center;
    font-size: var(--base-font-size);
}

.app.bgImage {
    background-repeat: no-repeat;
}

.folder__title {
    color: white;
    padding: 20px 20px 0;
    font-size: 1em;
    text-align: center;
    font-weight: bold;
}

.folder__items {
    width: 100%;
    padding: 10px 5px;
}

.folder__items.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.folder__item {
    text-decoration: none;
}

.folder__item.grid {
    text-align: center;
    padding: 20px 5px;
}

.folder__item.list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background-color: var(--color-folder-item-list-background);
    border-radius: 5px;
    margin-bottom: 5px;
}

.folder_item_icon_wrapper {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 18px;
    overflow: hidden;
}

.list .folder_item_icon_wrapper {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin-right: 10px;
}

.folder_item_icon {
    width: 100%;
    height: 100%;
}

.folder_item_name {
    font-size: 0.9em;
    text-decoration: none;
    overflow-wrap: anywhere;
}

.list .folder_item_name {
    color: var(--color-folder-item-name-list);
}

.grid .folder_item_name {
    color: var(--color-folder-item-name-grid);
    margin-top: 3px;
}

.grid .folder_item_name:not(.highlighted) {
    text-shadow: 0 0 2px #333;
}

/**************************************   */

.file {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-top: 40px;
}

.file_top_bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: var(--color-top-bar);
    padding: 10px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.file_back_button {
    color: var(--color-top-bar);
    padding: 0 10px;
    flex-shrink: 0;
    flex-grow: 0;
}

.file_back_button_icon {
    vertical-align: top;
}

.file_top_bar_title > p {
    margin: 0;
}

.file_image,
.file_video {
    width: 100%;
    height: 100%;
    display: block;
}

.file_text {
    width: 90%;
    height: 100%;
    display: block;
    overflow: auto;
    line-height: 1.5;
    color: var(--color-file-text);
    padding: 20px;
    padding-bottom: var(--phone-footer-height);
    background-color: var(--color-file-text-background);
    backdrop-filter: blur(5px);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.file_text > p:first-child {
    margin-top: 0;
}

.file_text > p:last-child {
    margin-bottom: 0;
}

.file_text ul {
    padding-inline-start: 20px;
}

.file_text img {
    max-width: 100%;
    height: auto;
}

.file_text a {
    color: #039be5;
}

.file_audio {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
}

.file_audio__player {
    background-color: var(--color-audio-player-background);
    border-radius: 20px;
    width: 100%;
    display: grid;
    place-items: center;
}

.file_audio__player :global(.rhap_container) {
    width: 100% !important;
    padding: 10px;
}

.file_audio__player :global(.rhap_progress-section) {
    margin-left: -8%;
    margin-right: 10px;
}

.file_audio__player :global(.rhap_time) {
    color: var(--color-audio-player-primary);
}

.file_audio__player :global(.rhap_progress-container) {
    margin-right: calc(10px + 2%);
}

.file_audio__player :global(.rhap_controls-section) {
    margin: 0 0 0 -6% !important;
}

.file_audio__player :global(.rhap_progress-indicator) {
    background-color: var(--color-audio-player-primary);
}

.file_audio__player :global(.rhap_progress-filled) {
    background-color: var(--color-audio-player-progress);
    left: -1px;
}

.file_audio__player :global(.rhap_main-controls-button) {
    color: var(--color-audio-player-primary);
}

.file_audio__transcript {
    background-color: var(--color-audio-transcript-background);
    padding: 0.8em;
    margin-top: 10px;
    border-radius: 15px;
    overflow-y: scroll;
    width: 100%;
}

.file_audio__transcript__text {
    font-size: 14px;
    line-height: 1.2;
    color: var(--color-audio-transcript-text);
}

.file_audio__transcript__text > p:first-child {
    margin-top: 0;
}

.file_audio__transcript__text > p:last-child {
    margin-bottom: 0;
}

.file_audio__transcriptButton {
    font-size: 14px;
    cursor: pointer;
    margin-top: 7px;
    text-align: center;
    color: var(--color-audio-player-primary);
}

.progress__overlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 90%;
    overflow: hidden;
    margin-bottom: 80px;
    background-color: var(--color-progress-overlay-background);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    transform: translate(-50%, 0);
}

.progress__text {
    color: var(--color-progress-text);
    padding: 10px 0;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.progress__container {
    height: 15px;
    background: linear-gradient(
        45deg,
        var(--color-progress-container-primary),
        var(--color-progress-container-secondary)
    );
    border-radius: 7.5px;
    overflow: hidden;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
}

.progress__bar {
    height: 100%;
    background: var(--color-progress-bar-background);
    transition: width 0.3s ease;
    box-shadow: 0 3px 10px var(--color-progress-bar-box-shadow);
    border-radius: 7.5px 0 0 7.5px;
}
