.cardList {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 5px 0; /* leave room for highlight */
}

.card {
    padding: 10px;
    height: 200px;
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
}

.card:first-child {
    padding-top: 0;
}

.card__content__container {
    width: 100%;
    height: 100%;
    display: block;
    pointer-events: none;
}

.card__content__container.open {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    overflow: hidden;
}

.card__content {
    pointer-events: auto;
    position: relative;
    border-radius: 3px;
    background: var(--color-card-background);
    color: var(--color-card-text);
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.open .card__content {
    height: 100%;
    overflow: hidden;
}

.image__container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 200px;
    transform: translateZ(0);
}

.open .image__container {
    pointer-events: none;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.card__content__children {
    opacity: 0;
    color: var(--color-card-text);
    position: absolute;
    top: 270px;
    bottom: var(--phone-footer-height);
    width: 100%;
    padding: 10px 15px 0;
    overflow: auto;
}

.open .card__content__children {
    opacity: 1;
}

.title {
    position: absolute;
    top: 0;
    left: 0;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.open .title {
    pointer-events: none;
}

.title__title {
    color: var(--color-card-image-text);
    margin-top: 5px;
    font-size: 20px;
    font-weight: bold;
}

.title__subtitle {
    color: var(--color-card-image-text);
    font-size: 14px;
    text-transform: uppercase;
}

.overlay {
    z-index: 1;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    will-change: opacity;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
