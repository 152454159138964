.menu {
    position: relative;
    height: 100%;
    width: 80%;
    margin-left: -80%;
    background: radial-gradient(
        circle at 75%,
        rgba(14, 19, 48, 0.8) 0%,
        rgba(14, 19, 48, 0.9) 40%
    );
}

.items {
    padding-top: 20px;
    font-size: var(--base-font-size);
}

.items.bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
}

.item {
    padding: 15px 30px;
    color: #fff;
    display: flex;
    align-items: center;
}

.item.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.item__icon {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    vertical-align: top;
    margin-right: 15px;
    opacity: 0.8;
}

.item__icon.svg {
    width: 1.1em;
    height: 1.1em;
    margin-right: 13px;
    opacity: 1;
}

.item__title {
    padding-right: 20px;
}

.item__newTag {
    position: absolute;
    right: 15px;
    font-weight: bold;
    font-size: 0.75em;
    border-radius: 0.4em;
    padding: 6px 5px;
    background-color: var(--color-text-highlight);
}

@media screen and (height <= 475px) {
    .items.bottom {
        display: none;
    }
}
