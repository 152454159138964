.player {
    flex-shrink: 0;
}

.player :global(.rhap_container) {
    padding: 0;
    width: 240px;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.player :global(.rhap_play-pause-button) {
    margin-left: 0;
}

.player :global(.rhap_controls-section) {
    flex: 0;
    margin-right: 0;
}

.player :global(.rhap_time) {
    color: var(--color-chat-incoming-text);
    margin-left: 8px;
    font-size: 0.9em;
}

.playButton {
    display: inline-block;
    vertical-align: top;
    font-size: 40px;
    line-height: 40px;
    width: 40px;
    height: 40px;
}
