.lockScreen {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: var(--zindex-phone-lock-screen);
    background-color: white;
    opacity: 1;
    transition:
        visibility 0s linear 0s,
        opacity 0.5s linear;
}

.lockScreen.unlocked {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition:
        visibility 0s linear 0.5s,
        opacity 0.5s linear;
}

.lockScreen__contents,
.lockScreen__background,
.lockScreen__inner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.lockScreen__background {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    object-position: center center;
}

.lockScreen__contents {
    position: relative;
    z-index: 2;
}

.lockScreen__inner {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition:
        visibility 0s linear 0.1s,
        opacity 0.1s linear;
    padding: 100px 30px;
    color: white;
    text-shadow: 0 0 2px black;
}

.lockScreen__inner.visible {
    opacity: 1;
    visibility: visible;
    transition:
        visibility 0s linear 0s,
        opacity 0.1s linear;
}

.lockScreen__time {
    font-size: 40px;
    text-shadow: 0 0 4px black;
}

.lockScreen__date {
    font-size: 28px;
    text-shadow: 0 0 4px black;
}

.lockScreen__swipeToUnlock {
    width: 100%;
    position: absolute;
    bottom: 100px;
    left: 0;
    text-align: center;
}

.keyboardWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition:
        visibility 0s linear 0.1s,
        opacity 0.1s linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
    text-shadow: 0 0 2px black;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
}

.keyboardWrapper.visible {
    opacity: 1;
    visibility: visible;
    transition:
        visibility 0s linear 0s,
        opacity 0.1s linear;
}

.keyboardWrapper.noPadding {
    padding: 0;
}

.batteryIcon {
    position: absolute !important;
    top: 10px;
    top: clamp(10px, var(--safe-area-inset-top), 50px);
    right: 10px;
    height: fit-content;
    z-index: 2;
}
