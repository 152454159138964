.app {
    --color-background: #000;
    --color-text: #fff;
    --color-title: var(--color-text);
    --color-info-text: rgba(255, 255, 255, 0.8);
    --color-activity-background: var(--color-background);
    --color-activity-text: var(--color-text);
    --color-activity-date: rgba(255, 255, 255, 0.8);
    --header-height: 45px;
    --transitions-duration: 0.3s;
    --transitions-easing-function: cubic-bezier(0.33, 1, 0.68, 1);

    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
}

.header {
    width: 100%;
    position: relative;
    height: var(--header-height);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    flex-shrink: 0;
}

.header__title,
.header__done {
    color: var(--color-title);
    transition:
        transform var(--transitions-duration) var(--transitions-easing-function),
        opacity var(--transitions-duration) var(--transitions-easing-function);
}

.header__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 1;
}

.header__done {
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 16px;
}

.header__title.hidden,
.header__done.hidden {
    pointer-events: none;
    transform: translateX(-150%);
    opacity: 0;
}

.header__done__icon {
    vertical-align: top;
}

.activityList {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

.activityListItem {
    display: flex;
    color: var(--color-text);
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    overflow: hidden;
}

.activityListItem:not(:first-child) {
    margin-top: 15px;
}

.activityListItem__thumbnail {
    width: 16%;
    height: auto;
    flex-shrink: 0;
}

.activityListItem__info {
    width: 100%;
    padding: 0 15px;
    text-align: left;
    font-size: 14px;
    color: var(--color-info-text);
    line-height: 1.3;
    flex-grow: 0;
    flex-shrink: 1;
}

.activityListItem__title {
    font-size: 1.2em;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.activityListItem__icon {
    height: 1em;
    width: auto;
    object-fit: contain;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 0.2em;
}

.activityListItem__openIcon {
    flex-shrink: 0;
}

.activity {
    position: absolute;
    top: var(--header-height);
    left: 0;
    width: 100%;
    height: calc(100% - var(--header-height));
    background-color: var(--color-activity-background);
    color: var(--color-activity-text);
    transform: translateX(100%);
    transition: transform var(--transitions-duration)
        var(--transitions-easing-function);
    text-align: center;
}

.activity.open {
    transform: translateX(0);
}

.activity__title {
    font-size: 1.2em;
    font-weight: bold;
}

.activity__date {
    color: var(--color-activity-date);
    font-size: 0.85em;
    margin-top: 5px;
}

.activity_imageContainer {
    width: 100%;
    height: 70%;
    overflow: hidden;
    margin-top: 15px;
    position: relative;
}

.activity__image {
    width: 100%;
    height: 100%;
}

.activity__details {
    margin-top: 15px;
}
