.confettiWrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: var(--zindex-confetti);
}
