.app {
    --color-title: #606060;
    --color-index-text: #606060;
    --color-note-text: black;
    --color-background: #fff;
    --color-index-background: #ffd231;
    --color-item-height: 155px;
    --color-item-title-height: 2em;
    --color-index-item-title-background: #ffd231;
    --color-index-item-background: #fff;
    --image-app-index-background: linear-gradient(
        #eee 0.1em,
        transparent 0.1em
    );
    --header-height: 130px;

    width: 100%;
    height: 100%;
    font-size: var(--base-font-size);
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    background-color: var(--color-background);
}

.index {
    height: 100%;
    background-image: var(--image-app-index-background);
    background-size: cover;
}

.defaultBackground .index {
    background-size: 100% 1.2em;
}

.index__scroller {
    overflow: auto;
    height: calc(100% - var(--header-height));
}

.index__header {
    padding: 2rem;
    height: var(--header-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    background-color: var(--color-index-background);
    z-index: 10;
    position: relative;
}

.index__header__icon {
    width: 1.5em;
    height: 1.5em;
    margin: auto;
    margin-right: 0.5em;
}

.index__header__icon.custom {
    width: 2.5em;
    height: 2.5em;
    border-radius: 4px;
}

.index__header__text {
    display: flex;
    flex-direction: row;
}

.index__title {
    font-size: 32px;
    font-weight: bold;
    color: var(--color-title);
    height: 100%;
    margin: auto;
}

.index__subtitle {
    margin-top: 0.5em;
    color: var(--color-title);
}

.index__notes {
    color: var(--color-note-text);
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    width: 100%;
    grid-gap: 20px;
    padding: 20px 0 35px;
}

.index__note {
    background-color: var(--color-index-item-background);
    color: var(--color-note-text);
    border-radius: 15px;
    width: 100%;
    max-width: 155px;
    margin: auto;
    position: relative;
    animation: app-notes-note-appear 0.2s cubic-bezier(0.34, 1.56, 0.64, 1) both;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
    height: var(--color-item-height);
}

.index__note:nth-child(odd) {
    margin-right: 0;
}

.index__note:nth-child(even) {
    margin-left: 0;
}

.index__note.noExcerpt {
    height: 4em;
}

.index__note__contents {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
}

.index__note__title {
    color: var(--color-title);
    background-color: var(--color-index-item-title-background);
    background-image: var(--color-index-item-title-background);
    border-radius: 15px 15px 0 0;
    padding: 0 10px;
    vertical-align: middle;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: var(--color-item-title-height);
    line-height: 2.5;
}

.index__note__text {
    color: var(--color-note-text);
    line-height: 1.2em;
    padding: 10px;
    height: 100%;
    overflow: hidden;
}

.note__title,
.index__title,
.note__backButton {
    color: var(--color-title);
}

/**************************************   */

.note {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.note__topBar {
    font-size: 1.25em;
    height: 3em;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background-color: var(--color-index-background);
}

.note__backButton {
    display: inline-block;
    font-size: 1em;
    padding: 1em 0.8em;
}

.note__content {
    height: 100%;
    padding: 20px;
    overflow: auto;
    color: var(--color-note-text);
}

.note__title {
    font-weight: bold;
    font-size: 1em;
}

.note__text {
    font-size: 1.25em;
    line-height: 1.5;
}

.note__text.nowrap {
    white-space: nowrap;
}

.note__text > p:first-child {
    margin-top: 0;
}

.note__text > p:last-child {
    margin-bottom: 0;
}

.note__text ul {
    padding-inline-start: 20px;
}

@keyframes app-notes-note-appear {
    0% {
        transform: translateY(15px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
