.app {
    --color-background: #fff;
    --color-title: #000;
    --color-description: #000;

    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    background-color: var(--color-background);
    position: relative;
}

.app__backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.scroller {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.title,
.logo {
    font-size: 2em;
    text-align: center;
    margin: 1em 0;
    position: relative;
    z-index: 1;
}

.title {
    color: var(--color-title);
}

.logo {
    width: 80%;
    height: auto;
    align-self: center;
    margin-bottom: 0;
}

.description {
    margin-top: 30px;
    font-size: 1.125em;
    padding: 0 1rem;
    color: var(--color-description);
}

.description > p:first-child {
    margin-top: 0;
}

.description > p:last-child {
    margin-bottom: 0;
}

.inventory {
    padding: 1em;
    display: grid;
}

.item__field {
    padding: 0.5em;
}

.item__field.header {
    font-weight: bold;
}

.item__field__markdown > p:first-child {
    margin-top: 0;
}

.item__field__markdown > p:last-child {
    margin-bottom: 0;
}

.item__field__image {
    width: 100%;
    height: auto;
}
