.button {
    display: inline-block;
    border-radius: 8px;
    box-shadow:
        0 8px 0 #c5376d,
        0 15px 20px rgba(0, 0, 0, 0.35);
    transition: box-shadow 0.1s ease-in-out;
    font-size: 20px;
    color: #fff;
    margin-top: 50px;
}

.inner {
    display: inline-block;
    padding: 20px 30px;
    background-color: #ec528d;
    background-image: linear-gradient(
        hsla(338deg, 90%, 80%, 0.8),
        hsla(338deg, 90%, 70%, 0.2)
    );
    border-radius: 8px;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.15);
    line-height: 1;
    text-shadow: 0 -1px 1px rgba(175, 49, 95, 0.7);
    transition:
        background-color 0.2s ease-in-out,
        transform 0.1s ease-in-out;
}

/*
.button:hover .inner {
    background-color: #ec6a9c;
    text-shadow: 0 -1px 1px rgba(175, 49, 95, 0.9),
        0 0 5px rgba(255, 255, 255, 0.8);
}
*/
.button:active {
    box-shadow:
        0 8px 0 #c5376d,
        0 12px 10px rgba(0, 0, 0, 0.3);
}

.button:active .inner {
    transform: translate(0, 4px);
    text-shadow:
        0 -1px 1px rgba(175, 49, 95, 0.9),
        0 0 5px rgba(255, 255, 255, 0.8);
}
