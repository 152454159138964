.loginButton {
    margin: 5px 0;
    line-height: 1.5em;
}

.loginButton__icon {
    height: 1.5em;
    vertical-align: top;
    margin-right: 0.65em;
}

.loginButton__icon__disabled {
    filter: grayscale(1) brightness(0.7);
    opacity: 0.5;
}
