.highlightHintBanner_container {
    position: absolute;
    width: 100%;
    height: 1px;
    top: clamp(25px, var(--safe-area-inset-top), 40px);
    z-index: var(--zindex-alert);
}

.highlightHintBanner_content {
    font-family: var(--font-family-default);
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: fit-content;
    margin: auto;
    vertical-align: middle;
    border-radius: 20px;
    background: linear-gradient(
        1.88deg,
        rgba(249, 52, 252, 0.6) -4.98%,
        rgba(209, 0, 213, 0.6) 98.99%
    );
    padding-right: 1rem;
}

.highlightHintBanner_icon {
    height: 2rem;
    width: auto;
    margin-right: 0.5em;
    vertical-align: middle;
    overflow: hidden;
    border: 1px solid white;
    border-radius: 20px;
}
