.whisper {
    position: absolute;
    top: 3%;
    left: 0;
    font-size: 30px;
    font-family: "Grandstander", cursive;
    opacity: 1;
    pointer-events: none;
    width: 100%;
    overflow: hidden;
    z-index: var(--zindex-whisper-effect);
    animation: enter-exit linear;
}

@keyframes enter-exit {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.wrapper {
    width: 100%;
    position: relative;
    overflow-x: hidden;
    padding: 0.8em 0;
}

.text.i1,
.text.i2,
.text.i3,
.text.i4 {
    position: absolute;
    top: 0.8em;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: visible;
}

.text__inner {
    display: block;
    opacity: 0.8;
    line-height: 60px;
    white-space: nowrap;
    text-align: center;
    animation: anim 60s infinite linear;
}

.i1 > .text__inner {
    opacity: 0.3;
    font-size: 0.7em;
    animation-delay: -20s;
    animation-duration: 50s;
    filter: blur(1px);
}

.i2 > .text__inner {
    opacity: 0.1;
    font-size: 2em;
    animation-delay: -40s;
    animation-duration: 80s;
    filter: blur(2px);
    margin-top: -10px;
}

.i3 > .text__inner {
    opacity: 0.1;
    font-size: 2.4em;
    animation-delay: -70s;
    animation-duration: 100s;
    filter: blur(3px);
    margin-top: -5px;
}

.i4 > .text__inner {
    opacity: 0.1;
    font-size: 1.5em;
    animation-delay: -90s;
    animation-duration: 120s;
    filter: blur(2px);
    margin-top: 5px;
}

@keyframes anim {
    20% {
        transform: translate(-20%, 10%);
    }

    40% {
        transform: translate(20%, -10%);
    }

    60% {
        transform: translate(-20%, 10%);
    }

    80% {
        transform: translate(20%, -10%);
    }
}

@media screen and (width <= 600px) {
    .hint {
        top: 0;
        font-size: 18px;
    }

    .wrapper {
        padding-top: 0;
    }
}
