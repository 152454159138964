.studio {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.studioWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.studio__editArea {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.studio__bar {
    flex-grow: 1;
    flex-shrink: 0;
}

.studio__bar__title {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.studio__bar__icon {
    width: 1.5rem;
    color: #fff;
}

.studio__bar__saving {
    padding-right: 1em;
}

.studio__bottomNav {
    flex-grow: 0;
    flex-shrink: 0;
}

.studio__loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.studio__loading__text {
    font-size: 20px;
    margin-top: 10px;
}

.screenSizeWarning {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 7px 10px;
    color: #9f6000;
    background-color: #feefb3;
}

.screenSizeWarning__text {
    flex-shrink: 1;
}

.screenSizeWarning__dismiss {
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 1.5em;
    margin-left: 5px;
    cursor: pointer;
}

.communityMissionsSubmission {
    margin-top: 30px;
    width: 100%;
}

.reviewForm__errorMessage {
    color: var(--color-text-error);
    font-size: 14px;
    margin-top: 5px;
}

.studioFooter {
    display: flex;
    background-color: #fff;
    padding: 0 7.5%;
    z-index: 10;
}

.studioFooter__nav {
    border-top: 1px solid black;
    width: 100%;
    display: flex;
}

.studioFooter__nav__Item {
    margin: auto;
    font-size: 16px;
    color: black;
}

.studioFooter__nav__Item__logo {
    display: flex;
    margin-left: 0;
    line-height: 1.25rem;
}

.logo {
    width: 45px;
    height: fit-content;
    margin: 0 12px;
}

.grayText {
    color: #9d9d9d;
}

.studioFooter__downloadButtons {
    justify-content: end;
}

.studioFooter__downloadButtons .studioFooter__nav__Item {
    margin: auto 0;
    padding: 7px 0 0;
}

.studioFooter__nav__appButton {
    width: 120px;
}

.studioFooter__Button__palyStore {
    width: 155px;
}
