.app {
    --color-app-background: #eaeaeb;
    --color-transaction-background: #fff;
    --color-balance-card-background: #000;
    --color-balance-card-text: #fff;
    --color-section-title: #000;
    --color-payment-request-background: #fff;
    --color-payment-request-name: rgba(0, 0, 0, 0.8);
    --color-payment-request-reason: #000;
    --color-pay-button-background: #0c74df;
    --color-pay-button-text: #fff;
    --color-amount-in: #15a23d;
    --color-amount-out: #f4364c;

    width: 100%;
    height: 100%;
    font-size: 16px;
    padding-bottom: var(--phone-footer-height);
    overflow: hidden;
    background-color: var(--color-app-background);
}

.scroller {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}

.header {
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    padding-top: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.header::before {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 50%;
    width: 100%;
    height: 50px;
    transform: translateX(-50%);
    background-color: var(--color-header-background);
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    z-index: 0;
}

.header__id {
    margin-top: 15px;
    position: relative;
    z-index: 1;
}

.header__id__name {
    font-weight: bold;
}

.header__id__username {
    margin-left: 5px;
}

.balance {
    text-align: left;
    width: 260px;
    height: 163px; /* credit card aspect ratio: 1.586 */
    margin: 20px auto 0;
    padding: 20px;
    border-radius: 20px;
    background: var(--color-balance-card-background);
    background-size: cover;
    color: var(--color-balance-card-text);
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
}

.balance__backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.balance__title {
    font-size: 0.85em;
    opacity: 0.7;
    position: relative;
    z-index: 2;
}

.balance__value {
    align-items: flex-start;
    justify-content: center;
    font-size: 2em;
    margin: 30px 0;
    position: relative;
    z-index: 2;
}

.balance__appTitle {
    font-size: 0.85em;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
}

.section {
    width: 100%;
    margin-top: 40px;
    padding: 0 10px;
}

.section__title {
    color: var(--color-section-title);
    font-weight: bold;
    font-size: 1.125em;
}

.section__empty {
    font-size: 0.8em;
    margin-top: 5px;
}

.paymentRequest {
    margin-top: 10px;
    border-radius: 10px;
    background-color: var(--color-payment-request-background);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.paymentRequest__header {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.paymentRequest__name {
    font-weight: bold;
    color: var(--color-payment-request-name);
}

.paymentRequest__details {
    margin-left: 10px;
    text-align: left;
    width: 100%;
}

.paymentRequest__reason {
    color: var(--color-payment-request-reason);
    margin-top: 0.2em;
    font-size: 0.9em;
}

.paymentRequest__amount {
    font-weight: bold;
    align-self: flex-start;
}

.in .paymentRequest__amount {
    color: var(--color-amount-in);
}

.out .paymentRequest__amount {
    color: var(--color-amount-out);
}

.paymentRequest__buttonWrapper {
    text-align: center;
    margin-top: 7px;
}

.paymentRequestActionButton {
    display: block;
    background-color: var(--color-pay-button-background);
    color: var(--color-pay-button-text);
    padding: 0.7em 1.5em;
    font-size: 15px;
    border-radius: 0.5em;
    position: relative;
    cursor: pointer;
    font-weight: 500;
}

.loading .paymentRequestActionButton__content {
    visibility: hidden;
}

.paymentRequestActionButton__spinnerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.completedPayment {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-top: 8px;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    background-color: var(--color-transaction-background);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.completedPayment__amount {
    position: absolute;
    top: 13px;
    right: 10px;
    font-weight: bold;
}

.completedPayment__amount.in {
    color: var(--color-amount-in);
}

.completedPayment__amount.out {
    color: var(--color-amount-out);
}

.completedPayment__details {
    color: rgba(0, 0, 0, 0.6);
    margin-left: 10px;
    text-align: left;
    width: 100%;
}

.completedPayment__name {
    font-size: 0.9em;
    padding-right: 7em;
}

.completedPayment__reason {
    color: #000;
    margin-top: 0.2em;
    font-size: 0.9em;
}

.completedPayment__name__value {
    font-weight: bold;
}
