.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: calc(20px + var(--safe-area-inset-top));
    height: 100%;
    width: 100%;
}

.lockIcon {
    width: 36px;
    height: 36px;
    align-self: center;
    justify-self: center;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px;
    flex-shrink: 0;
}

.title {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.textbox {
    display: inline-block;
    line-height: 2;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 50px 0 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

.textboxForm {
    text-align: center;
    width: 85%;
}

.cancel {
    margin-top: 10px;
    margin-bottom: 60px;
    flex-shrink: 0;
    text-align: center;
    display: inline-block;
    padding: 10px;
}

.textbox.animated {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

@keyframes shake {
    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}
