.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
}

.title {
    font-size: 20px;
    text-align: center;
}

.title,
.cancel {
    opacity: 1;
    transition: opacity 0.3s linear;
}

.success .title,
.success .cancel {
    opacity: 0;
}

.root.darkMode {
    color: #fff;
}

.keyboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    margin-top: 20px;
    touch-action: none;
}

.success .keyboard {
    animation: rotate-out-center 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.key {
    padding: 20px;
    margin: 10px;
    touch-action: none;
}

.key::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    border: 1px solid #333;
    transition: background-color 0.3s;
    animation: scale-in 0.2s both;
}

.key.on::before {
    background-color: #333;
    animation: jello-horizontal 0.5s ease-out both;
}

.darkMode .key::before {
    border-color: rgba(255, 255, 255, 0.8);
}

.darkMode .key.on::before {
    background-color: rgba(255, 255, 255, 0.8);
}

.pasteButton {
    padding: 6px 15px;
    background-color: #333;
    border-radius: 15px;
    color: #fff;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: -20px;
}

.pasteButton::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: calc(50% - 9px);
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #333;
}

@keyframes shake {
    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes jello-horizontal {
    0% {
        transform: scale3d(1, 1, 1);
    }

    30% {
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        transform: scale3d(1.05, 0.95, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes scale-in {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes rotate-out-center {
    0% {
        transform: rotate(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: rotate(-540deg) scale(1.2);
        opacity: 0;
    }
}
